<template>
  <div>
    <div class="accordion" role="tablist">
      <div class="widget-header border-bottom">
        <div class="m-0 h4 d-flex" v-b-toggle="`sipCall-accordion-${data.id}`">
          <p class="mb-0 mx-auto text-center">
            {{ formatDateAssigned(data.created_at) }}
          </p>
          <template>
            <feather-icon
              :icon="isCollapseOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              size="24"
            />
          </template>
        </div>
      </div>
      <b-collapse
        :id="`sipCall-accordion-${data.id}`"
        :accordion="`sipCall-accordion-${data.id}`"
        role="tabpanel"
        :visible="false"
        v-model="isCollapseOpen"
      >
        <div>
          <table class="table">
            <tr>
              <th>ID</th>
              <td>{{ data.id }}</td>
            </tr>
            <tr>
              <th>SessionId</th>
              <td>{{ data.sessionId }}</td>
            </tr>
            <tr>
              <th>{{ $t("grid.slots.info.sip_call.state") }}</th>
              <td>{{ data.state }}</td>
            </tr>
            <tr>
              <th>{{ $t("grid.slots.info.sip_call.from") }}</th>
              <td>{{ device_unit_user_calling_party_number }}</td>
            </tr>
            <tr>
              <th>{{ $t("grid.slots.info.sip_call.to") }}</th>
              <td>{{ device_unit_user_remote_party_number }}</td>
            </tr>
            <tr>
              <th>{{ $t("grid.slots.info.sip_call.origin_date") }}</th>
              <td>{{ formatDateAssigned(data.created_at) }}</td>
            </tr>
            <tr
              v-if="data.recording_url && $can('read_record_call', 'SipCall')"
            >
              <th>{{ $t("grid.slots.info.sip_call.audio_record") }}</th>
              <td>
                <audio
                  ref="audioTag"
                  controls
                  :controlsList="
                    $can('download_record_call', 'SipCall')
                      ? 'download'
                      : 'nodownload'
                  "
                >
                  <p>Your browser doesn't support HTML5 audio.</p>
                </audio>
              </td>
            </tr>
          </table>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import moment from "moment-timezone";
import { EventBus } from "@/libs/event-bus";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  props: {
    data: Object,
  },
  data() {
    return {
      isCollapseOpen: true,
      device_unit_user_calling_party_number: this.$props.data.callingPartyNumber,
      device_unit_user_remote_party_number: this.$props.data.remotePartyNumber,
    };
  },
  computed: {},
  methods: {
    makeCall(data) {
      EventBus.emit(`call_device`, { id: data.callingPartyNumber });
    },
    formatDateAssigned(value) {
      const formattedDate = moment(value).format("DD/MM/YYYY HH:mm:ss");
      return formattedDate;
    },
    getDeviceUnitUser(device_id) {
      let unit_user = device_id;
      store
        .dispatch("sip/getDevice", {
          device_id: device_id,
        })
        .then((response) => {
          console.log({ response });
          if (response.device.units) {
            if (response.device.units.lenght) {
              unit_user += " - " + response.device.units[0].description;
            }
          } else if (response.user) {
            unit_user += " - " + response.user.email;
          }
        })
        .catch((e) => {
          console.log({ e });
        });
      return unit_user;
    },
    setDeviceUnitUserCallingPartyNumber() {
      let unit_user = this.$props.data.callingPartyNumber;
      store
        .dispatch("sip/getDevice", {
          device_id: this.$props.data.callingPartyNumber,
        })
        .then((response) => {
          if (response.device.units) {
            if (response.device.units.length) {
              unit_user += " - " + response.device.units[0].description;
            }
          } else if (response.user) {
            unit_user += " - " + response.user.email;
          }
          this.device_unit_user_calling_party_number = unit_user;
        })
        .catch((e) => {
          console.log({ e });
        });
    },
    setDeviceUnitUserRemotePartyNumber() {
      let unit_user = this.$props.data.remotePartyNumber;
      store
        .dispatch("sip/getDevice", {
          device_id: this.$props.data.remotePartyNumber,
        })
        .then((response) => {
          if (response.device.units) {
            if (response.device.units.length) {
              unit_user += " - " + response.device.units[0].description;
            }
          } else if (response.user) {
            unit_user += " - " + response.user.email;
          }
          this.device_unit_user_remote_party_number = unit_user;
        })
        .catch((e) => {
          console.log({ e });
        });
    },
    showMessage(title, msg, variant = "success") {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title,
          variant,
          icon: "BellIcon",
          text: msg,
          timeout: 5000,
        },
      });
    },
  },
  mounted() {
    this.setDeviceUnitUserCallingPartyNumber();
    this.setDeviceUnitUserRemotePartyNumber();
    if (this.$props.data.recording_url) {
      let recording;
      store
        .dispatch("sip/getRecording", {
          sessionId: this.$props.data.sessionId,
        })
        .then((response) => {
          const url = URL.createObjectURL(response);
          const audio = this.$refs.audioTag;
          audio.src = url;
        })
        .catch((e) => {
          console.log({ e });
        })
        .finally(() => {
          console.log("Finally");
        });
    }
  },
};
</script>

<style></style>
