<template>
  <div
    :class="[
      'cam-container',
      `cam-${cameraId}`,
      pos ? `cam-${pos}` : '',
      {
        [`timeline-${tabInfo.id}`]: tabInfo.type === 'timeline',
        'show-tl-border': tabInfo.type === 'timeline',
      },
    ]"
    :style="{ borderColor: tlBorderColor }"
  >
    <div :class="`camera-${uuid}`"></div>
    <sidenav
      :is-sidebar-active.sync="isSidebarActive"
      @refetch-data="() => {}"
      :luna-master-account-options="lunaMasterAccountOptions"
      :luna-list-options="lunaListOptions"
      :image-url="imageUrl"
    />
    <sidenav-tag
        :id-camera="idCamera"
        :image-tag-url="imageTagUrl"
        :is-sidebar-active.sync="isSidebarTagActive"
        :value-date="valueDateTag"
    />
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { EventBus } from "@/libs/event-bus";
import { Camera } from "@omnicloud/cameras-sdk";
import layoutMixin from "@/mixins/layoutMixin";
import { SLOT_TYPES, SOURCES } from "@/config/layoutConfig";
import Sidenav from "../../../luna/face/Sidenav.vue";
import SidenavTag from "../../../tag/Sidenav.vue";
import store from "@/store";
import Config from "../../Timeline/Config";
import moment from "moment";
import { cameraOptions } from "@/libs/timeline";
import { C } from "sip.js/lib/core";

const timelineCameraEvents = {
  date_selection: "setDateSelection",
  update_recordings: "updateRecordings",
  play: "play",
  pause: "pause",
  set_current_time: "setCurrentTime",
  move_x_seconds: "moveXSeconds",
  speed: "setSpeed",
  timeline_video_ended: "setTimelineVideoEndend",
  sync_interval: "syncInterval",
  draw_landmarks: "drawLandmarks",
  clear_landmarks: "clearLandmarks",
};

const cameraEvents = [
  {
    listenTo: "set-main-camera",
    notifyTo: "set_main_camera",
    onTimelineModeOnly: false,
  },
  {
    listenTo: "set-secondary-camera",
    notifyTo: "set_secondary_camera",
    onTimelineModeOnly: false,
  },
  {
    listenTo: "reset-camera",
    notifyTo: "reset_camera",
    onTimelineModeOnly: true,
  },
  {
    listenTo: "play",
    notifyTo: "cam_play",
    onTimelineModeOnly: true,
  },
  {
    listenTo: "pause",
    notifyTo: "cam_pause",
    onTimelineModeOnly: true,
  },
  {
    listenTo: "timeupdate",
    notifyTo: "cam_timeupdate",
    onTimelineModeOnly: true,
  },
  {
    listenTo: "loadstart",
    notifyTo: "cam_loadstart",
    onTimelineModeOnly: true,
  },
  {
    listenTo: "video_ended",
    notifyTo: "video_ended",
    onTimelineModeOnly: true,
  },
];

export default {
  mixins: [layoutMixin],
  components: {
    Sidenav,
    SidenavTag
  },
  props: {
    pos: {
      type: Number | String,
    },
    parentId: {
      type: Number | String,
    },
    cameraId: {
      type: Number | String,
      default: null,
    },
    cameraType: {
      type: String,
      default: null,
    },
    dateRange: {
      type: Object,
    },
    nameSufix: {
      type: String,
      default: "",
    },
    host: {
      type: String,
      default: process.env.VUE_APP_BASE_URL,
    },
    source: {
      type: String
    },
    tabInfo: Object,
  },
  data() {
    const uuid = uuidv4();
    let token = localStorage.getItem("accessToken");

    if (this.host !== process.env.VUE_APP_BASE_URL) {
      const externalUser = this.$store.state.user.user.external_users.find(
        (external_user) => external_user.host == this.host
      );

      if (externalUser) token = externalUser.token;
    }

    return {
      uuid,
      isSidebarActive: false,
      isSidebarTagActive: false,
      camera: null,
      imageUrl: "",
      imageTagUrl: "",
      config: {
        isTag: false,
        dateRanges: this.dateRange,
        cameraId: this.cameraId,
        token,
        htmlElement: `.camera-${uuid}`,
        style: "float",
        apiUrl: `${this.host}/v1`,
        nameSufix: " (CST)",
        groupId: this.tabInfo.id,
        debugMode: "info|error",
        mode: "default",
        locale: this.$i18n.locale,
        frameColor: this.$store.state.user.user.frame_color,
        userId: this.$store.state.user.user.id,
        capabilities: this.defaultCapabilities(),
      },
      mode: "live",
      SLOT_TYPES,
      SOURCES,
      isMainCamera: false,
      isSynced: false,
      ready: false,
      tabSetTimeout: null,
      valueDateTag: null,
      idCamera: 0
    };
  },
  mounted() {
    try {
      if (this.cameraId && this.cameraType) {
        const camType = this.cameraType === "bridge" ? "vms" : this.cameraType;

        if (this.source === SOURCES.MAIN_SEARCH) {
            this.config.isTag = true;
        }

        this.camera = new Camera(camType, this.config);

        let buildPlayerCallback = null;

        if (this.dateRange.start && this.dateRange.end) {
          buildPlayerCallback = this.camera.buildPlayer(
            "historical",
            this.dateRange.start,
            this.dateRange.end
          );
          this.mode = "recorded";

          if (["camera_manager", "vxg"].includes(this.cameraType)) {
            buildPlayerCallback.finally(() =>
              EventBus.emit(`timeline:${this.tabInfo.id}:camera_ready`)
            );
          }
        } else {
          buildPlayerCallback = this.camera.buildPlayer();
          this.camera.getCameraInstance().on("clone:instance", () => {
            this.setSlotData(
              this.SLOT_TYPES.CAM_LIVE,
              this.camera.getCameraInstance().cameraData.name ||
                `CAM_${this.camera.getCameraInstance().cameraData.id}`,
              {
                camera: {
                  ...this.camera.getCameraInstance().cameraData,
                  camera_type: this.cameraType,
                  camera_id: this.camera.getCameraInstance().cameraData.id,
                },
              },
              SOURCES.ALARM
            );
          });
        }

        buildPlayerCallback.then(this.subscribeEvents);

        this.camera.getCameraInstance().on("create:recording", () => {
          this.setSlotData(
            this.SLOT_TYPES.CAM_RECORDED,
            this.camera.getCameraInstance().cameraData.name ||
              `CAM_${this.camera.getCameraInstance().cameraData.id}`,
            {
              camera: {
                ...this.camera.getCameraInstance().cameraData,
                camera_type: this.cameraType,
                camera_id: this.camera.getCameraInstance().cameraData.id,
              },
            },
            SOURCES.ALARM
          );
        });

        this.camera
          .getCameraInstance()
          .on("create:facial_recognition_enrollment", (img) => {
            this.imageUrl = img;
            this.isSidebarActive = true;
          });

        this.camera
          .getCameraInstance()
          .on("create:tag", (data) => {
              let { image,date } = data;

              this.imageTagUrl        = image;
              this.valueDateTag       = date;
              this.idCamera           = this.cameraId;
              this.isSidebarTagActive = true;
          });
      }

      this.attachTimelineListeners();
    } catch (error) {
      console.log(error);
    }

    store.dispatch("luna_master_account/getAll");
    store.dispatch("luna_list/getAll");
  },
  watch: {
    tabInfo: {
      handler(value) {
        if (
          this.camera &&
          this.camera.camera.mode !== "timeline" &&
          !this.camera.camera.capabilities.timelineBtn &&
          value.type === "timeline"
        ) {
          this.widgetTimeline(value);
        }

        if (
          this.camera &&
          !this.camera.camera.capabilities.detectionEventBtn &&
          value.type2 === "detection_event"
        ) {
          this.widgetDetectionEvent(value);
        }

        if (this.camera) {
          this.removeWidgetBtn(value);
        }
      },
      immediate: true,
    },
    "tabInfo.isActive"(value) {
      if (
        this.camera &&
        this.camera.camera.mode === "live" &&
        this.cameraType === "camera_manager"
      ) {
        if (value) {
          this.camera.reload();
        } else {
          this.camera.destroy();
        }
      }
    },
  },

  computed: {
    validData() {
      return this.cameraId && this.cameraType;
    },
    tlBorderColor() {
      if (this.camera && this.camera.camera.isTimelineMode && this.isSynced) {
        return this.isMainCamera ? "lime" : "yellow";
      }
      return "red";
    },
    lunaMasterAccountOptions() {
      return store.getters["luna_master_account/getSelectedItems"];
    },
    lunaListOptions() {
      return store.getters["luna_list/getSelectedItems"];
    },
  },

  beforeDestroy() {
    try {
      this.detachTimelineListeners();
      this.removeTimelineCameraEvents();
      this.camera.destroy();
      this.camera = null;
    } catch (error) {
      console.log(error);
    }
  },

  methods: {
    defaultCapabilities() {
      if (this.cameraType === "generic_device") {
        return {
          closeBtn: false,
          cloneBtn: true,
          recordingBtn: false,
          dateRangeBtn: false,
          timelineBtn: false,
          facialRecognitionEnrollment: false,
          checkAlarms: this.$store.state.user.user.check_alarms,
          tags: false,
          detectionEventBtn: false,
        };
      }

      return {
        closeBtn: false,
        cloneBtn: true,
        recordingBtn: true,
        dateRangeBtn: true,
        timelineBtn: this.tabInfo.type === SLOT_TYPES.TIMELINE,
        facialRecognitionEnrollment: true,
        checkAlarms: this.$store.state.user.user.check_alarms,
        tags: true,
        detectionEventBtn: this.tabInfo.type2 === SLOT_TYPES.DETECTION_EVENT,
      };
    },

    async enableTimeline(data) {
      this.mode = "timeline";
      this.isMainCamera = data.mainCamera;

      const { camera } = this.camera;
      let { start, end } = data;
      const timelineCameraConfig = Config.create(camera.cameraType);

      const fetchLastRecording = async () => {
        try {
          const resp = await cameraOptions(camera.cameraId, camera.cameraType);
          let start = moment(resp.latest_recording.start_date)
            .subtract(timelineCameraConfig.lastMinDateRequested, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");
          let end = resp.latest_recording.start_date;

          return { start, end };
        } catch (error) {
          throw error;
        }
      };

      const tryEnableTimelineInCamera = (config) => {
        return new Promise((resolve, reject) => {
          this.camera
            .setTimelineMode(config.mainCamera, config.start, config.end, {
              time_to_request: config.lastMinDateRequested,
            })
            .then(() => resolve())
            .catch((error) => reject(error));
        });
      };

      const activateTimeline = () => {
        this.isSynced = true;
        EventBus.emit(`timeline:${this.tabInfo.id}:ready`, {
          cameraId: this.cameraId,
        });
        setTimeout(this.subscribeEvents, 300);
        this.setTimelineCameraEvents();
      };

      if (!start) {
        try {
          const res = await fetchLastRecording();
          start = res.start;
          end = moment(res.end).add(10, "minutes").format("YYYY-MM-DD HH:mm:ss");
        } catch (error) {
          start = moment()
            .subtract(timelineCameraConfig.lastMinDateRequested, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");
          end = moment().format("YYYY-MM-DD HH:mm:ss");
        }
      }

      let config = {
        start: start,
        end: end,
        mainCamera: data.mainCamera,
        lastMinDateRequested: timelineCameraConfig.lastMinDateRequested,
      };

      try {
        // First try
        // TODO: Avoid used this to force
        if (camera.cameraType === "generic_device") {
          throw new Error("generic_device");
        }
        await tryEnableTimelineInCamera(config);
        activateTimeline();
      } catch (errorTry1) {
        // Second try
        try {
          const res = await fetchLastRecording();
          config.start = res.start;
          config.end = moment(res.end).add(1, "minutes").format("YYYY-MM-DD HH:mm:ss");
          await tryEnableTimelineInCamera(config);
          activateTimeline();
        } catch (errorTry2) {
          console.log("errorTry2", errorTry2);
        }
      }
    },

    async disableTimeline() {
      this.isMainCamera = false;
      this.isSynced = false;
      this.camera.setDisabledTimeline(this.dateRange.start, this.dateRange.end);
      this.mode = this.camera.getCameraInstance().isLiveVideo ? "live" : "recorded";
      this.removeTimelineCameraEvents();
      this.detachTimelineListeners();
    },

    async resetTimeline() {
      if (this.camera) await this.camera.destroy();
      this.$emit("refresh-widget");
    },

    emitCameraData() {
      if (this.mode === "timeline") {
        const camera = this.camera.camera;
        EventBus.emit(
          `timeline:${this.tabInfo.id}:ping-cam`,
          camera.cameraData.id_camera_proxy
        );
      }
    },

    remove() {
      try {
        this.camera.destroy();
        this.detachTimelineListeners();
        this.removeTimelineCameraEvents();
        this.$emit("cam-removed", this.pos);
      } catch (error) {
        console.log(error);
      }
    },

    widgetTimeline(value) {
      const dateRange = { ...this.dateRange };
      const format = "YYYY-MM-DD HH:mm:ss";
      dateRange.start = dateRange.start
        ? dateRange.start
        : moment().subtract(10, "minutes").format(format);
      dateRange.end = dateRange.end ? dateRange.end : moment().format(format);

      this.camera.setEnabledTimeline(dateRange.start, dateRange.end);
      this.mode = "recorded";
    },

    widgetDetectionEvent(value) {
      let capabilities = {
        recordingBtn: true,
        detectionEventBtn: true,
      };

      if (value.type == "timeline") {
        capabilities.closeBtn = false;
        capabilities.cloneBtn = true;
        capabilities.timelineBtn = true;
        this.configCameraTimeline(capabilities);
      } else {
        this.configCamera(capabilities);
      }
    },

    removeWidgetBtn(value) {
      if (value.type2 == "default" && this.camera.camera.capabilities.detectionEventBtn) {
        let capabilities = {
          recordingBtn: true,
          detectionEventBtn: false,
        };

        if (value.type == "timeline" && this.camera.camera.capabilities.timelineBtn) {
          capabilities.closeBtn = false;
          capabilities.cloneBtn = true;
          capabilities.timelineBtn = true;

          this.configCameraTimeline(capabilities);
        } else {
          this.configCamera(capabilities);
        }
      }
    },

    configCamera(capabilities) {
      const config = Object.assign(JSON.parse(JSON.stringify(this.config)), {
        capabilities,
      });
      this.camera.destroy();
      this.camera = new Camera(this.cameraType, config);
      this.camera.buildPlayer();
    },

    configCameraTimeline(capabilities) {
      const config = Object.assign(JSON.parse(JSON.stringify(this.config)), {
        capabilities,
      });
      this.camera.destroy();
      this.camera = new Camera(this.cameraType, config);
      this.camera.buildPlayer("historical", this.dateRange.start, this.dateRange.end);
      this.mode = "recorded";
    },

    // ================== Events section ==================

    attachTimelineListeners() {
      EventBus.on(
        `timeline:${this.tabInfo.id}:${this.cameraId}:activated`,
        this.enableTimeline
      );

      EventBus.on(
        `timeline:${this.tabInfo.id}:${this.cameraId}:disabled`,
        this.disableTimeline
      );

      EventBus.on(
        `timeline:${this.tabInfo.id}:${this.cameraId}:reset`,
        this.resetTimeline
      );
    },

    detachTimelineListeners() {
      EventBus.off(
        `timeline:${this.tabInfo.id}:${this.cameraId}:activated`,
        this.enableTimeline
      );
      EventBus.off(
        `timeline:${this.tabInfo.id}:${this.cameraId}:disabled`,
        this.disableTimeline
      );
      EventBus.off(`timeline:${this.tabInfo.id}:ping`, this.emitCameraData);
    },

    subscribeEvents() {
      const defaultEvents = cameraEvents.filter((event) => !event.onTimelineModeOnly);
      const timelineOnlyEvents = cameraEvents.filter((event) => event.onTimelineModeOnly);

      defaultEvents.forEach((event) => {
        this.camera.on(event.listenTo, (data) => {
          EventBus.emit(`timeline:${this.tabInfo.id}:${event.notifyTo}`, data);
        });
      });

      if (this.mode === "timeline") {
        timelineOnlyEvents.forEach((event) => {
          this.camera.on(event.listenTo, (data) => {
            EventBus.emit(`timeline:${this.tabInfo.id}:${event.notifyTo}`, data);
          });
        });
      }
    },

    setTimelineCameraEvents() {
      Object.entries(timelineCameraEvents).forEach(([event, handler]) => {
        EventBus.on(
          `timeline:${this.tabInfo.id}:${event}`,
          this.camera[handler].bind(this.camera)
        );
      });
    },

    removeTimelineCameraEvents() {
      Object.entries(timelineCameraEvents).forEach(([event, handler]) => {
        EventBus.off(
          `timeline:${this.tabInfo.id}:${event}`,
          this.camera[handler].bind(this.camera)
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cam-container {
  position: relative;
  height: 100%;
  border: none;

  &.show-tl-border {
    border-width: 2px;
    border-style: solid;
  }

  span {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
    z-index: 9;
    cursor: pointer;
  }

  div[class^="camera-"] {
    height: 100%;
  }
}
</style>
