<template>
  <div>
    <div v-if="incident">
      <p class="lead text-center text-capitalize">{{ $tc('incident.title', 1) }}</p>
      <table class="table">
        <tr>
          <th>{{ $t("log_incident.comment") }}</th>
          <td>{{ incident.comment }}</td>
        </tr>
      </table>
    </div>
    <div class="pb-2 text-center" v-else>
      <b-spinner label="Spinning" variant="primary"></b-spinner>
    </div>
  </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";

export default {
  props: {
    incident: Object,
  },
  components: {
    BSpinner,
  },
};
</script>

<style></style>
