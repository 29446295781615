<template>
  <div>

    <!-- Density -->
    <density-format
      v-if="!loading && actuatorData.camera_type === 'density_device'"
      :data="actuatorData"
    />

    <div class="pb-2 text-center" v-if="loading">
      <b-spinner label="Spinning" variant="primary"></b-spinner>
    </div>
  </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";
import axios from "@axios";
import DensityFormat from "./DensityFormat.vue";

export default {
  props: {
    data: Object,
  },
  components: {
    BSpinner,
    DensityFormat,
  },
  data() {
    return {
      loading: true,
      actuatorData: {},
    };
  },
  mounted() {
    axios.get(this.data.url).then(({ data }) => {
      this.actuatorData = data.data;
      this.loading = false;
    });
  },
};
</script>
