<template>
  <div>
    <p class="lead text-center font-medium-3">{{ $t("grid.slots.info.analytic_record.title") }}</p>
    <div class="mx-auto">
      <div class="row my-2">
        <div
          class="col-12"
          v-for="(media, index) in data.media"
          :key="index"
        >
        <img 
          class="img-thumbnail" 
          :src="media.url" alt=""
          @click="openImageModal(media.url)" 
          v-b-modal.image_modal 
        />
        </div>
      </div>
    </div>
    <table class="table table-sm">
      <tr>
        <th>{{ $t('grid.slots.info.analytic_record.camera_name') }}</th>
        <td>{{ data.camera_name }}</td>
      </tr>
      <tr>
        <th>{{ $t("grid.slots.info.analytic_record.alarm_category") }}</th>
        <td>{{ data.alarm_category }}</td>
      </tr>
      <tr>
        <th>{{ $t("alarm.alarm_type") }}</th>
        <td>{{ data.type_alarm }}</td>
      </tr>
      <tr>
        <th>{{ $t("alarm.created") }}</th>
        <td>{{ data.alarm_created_at }}</td>
      </tr>
      <tr>
        <th>{{ $t("grid.slots.info.analytic_record.priority") }}</th>
        <td>{{ data.priority }}</td>
      </tr>
      <tr>
        <th>{{ $t("grid.slots.info.analytic_record.matched_rules") }}</th>
        <td>{{ data.matched_rules }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import store from "@/store";

export default {
  props: {
    data: Object,
  },
  methods: {
    async openImageModal(imageUrl){
      await store.dispatch("image_modal/imageUrl", imageUrl );
    }
  },
};
</script>

<style></style>
