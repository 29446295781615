<template>
  <b-modal
    id="alarm_action_modal"
    title="Alarma"
    @show="onShow"
    @hidden="resetDataModal"
    hide-header
    centered
    no-close-on-esc
    no-close-on-backdrop
    v-if="selectedAlarm"
  >
    <p class="w-100 lead border-bottom pb-50">
      <span v-if="selectedAlarm.action === 'confirmed'">
        {{ $t("grid.modals.alarms_status.title_confirm") }}
      </span>
      <span v-if="selectedAlarm.action === 'attended'">
        {{ $t("grid.modals.alarms_status.title_attended") }}
      </span>
      <span v-if="selectedAlarm.action === 'canceled'">
        {{ $t("grid.modals.alarms_status.title_cancel") }}
      </span>
    </p>
    <div class="row">
      <div class="col-md-12">
        <validation-observer ref="refFormObserver">
          <validation-provider name="comments" rules="required" v-slot="{ errors }">
            <b-form-group
              :label="$t('grid.modals.alarms_status.protocol')"
              label-for="protocol"
              v-if="alarmCommentOptions.length"
            >
              <b-form-select
                v-model="alarmCommentSelected"
                :options="alarmCommentOptions"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              :label="$t('grid.modals.alarms_status.comments')"
              label-for="comments"
            >
              <b-form-textarea
                id="comments"
                name="comments"
                placeholder=""
                rows="3"
                max-rows="6"
                v-model="comments"
              >
              </b-form-textarea>
              <span class="d-block text-secondary mt-25" v-if="errors.length > 0">
                {{ errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>

          <b-form-group
            :label="$t('grid.modals.alarms_status.alarm_level')"
            label-for="alarm_level"
            v-if="actionType.attending && alarmLevelOptions.length"
          >
            <b-form-select
              v-model="alarmLevelSelected"
              :options="alarmLevelOptions"
            ></b-form-select>
          </b-form-group>
        </validation-observer>
      </div>
    </div>
    <template #modal-footer>
      <b-button variant="secondary" @click="onCancel()" :disabled="loading">
        {{ $t("grid.modals.alarms_status.btns.cancel") }}
      </b-button>
      <b-button variant="primary" @click="submit()" :disabled="loading">
        {{ $t("grid.modals.alarms_status.btns.send") }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BSpinner,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormSelect,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import axios from "axios";
import { EventBus } from "@/libs/event-bus";

export default {
  components: {
    BSpinner,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormSelect,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      comments: "",
      loading: false,
      actionType: {},
      alarmLevelSelected: null,
      alarmCommentSelected: null,
      alarmLevelOptions: [],
      alarmCommentOptions: [],
    };
  },
  computed: {
    selectedAlarm() {
      return store.state.grid.selectedAlarm;
    },
    locale() {
      return store.getters["user/getPreferences"].lang;
    },
  },
  methods: {
    onShow() {
      this.actionType = {
        attending: this.selectedAlarm.action === "confirmed",
        attended: this.selectedAlarm.action === "attended",
        canceled: this.selectedAlarm.action === "canceled",
      };

      const url = `${this.selectedAlarm.alarm.host}/v1/services/alarms/alarm-levels?id_tenant=${this.selectedAlarm.alarm.tenant.id}`;
      const commentUrl = `${this.selectedAlarm.alarm.host}/v1/alarm_comment?id_tenant=${
        this.selectedAlarm.alarm.tenant.id
      }${this.actionType.attending ? "&attending=1" : ""}${
        this.actionType.attended ? "&attended=1" : ""
      }${this.actionType.canceled ? "&canceled=1" : ""}&id_type_alarm[]=${
        this.selectedAlarm.alarm.id_alarm_type
      }`;

      let token = localStorage.getItem("accessToken");

      if (this.selectedAlarm.alarm.host !== process.env.VUE_APP_BASE_URL) {
        token = this.$store.state.user.user.external_users.find(
          (external_user) => external_user.host == this.selectedAlarm.alarm.host
        ).token;
      }

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-Host": this.selectedAlarm.alarm.host,
            "X-localization": this.locale,
          },
        })
        .then((resp) => {
          if (resp.data.data.length) {
            this.alarmLevelOptions.push({
              value: null,
              text: "Normal",
            });
            resp.data.data.forEach((item) =>
              this.alarmLevelOptions.push({ value: item.level, text: item.description })
            );
            this.alarmLevelSelected = null;
          }
        });
      axios
        .get(commentUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-Host": this.selectedAlarm.alarm.host,
            "X-localization": this.locale,
          },
        })
        .then((resp) => {
          if (resp.data.data.length) {
            resp.data.data.forEach((item) => this.alarmCommentOptions.push(item.comment));
            this.alarmCommentSelected = null;
          }
        });
    },
    async submit() {
      const validation = await this.$refs.refFormObserver.validate();

      if (validation) {
        const idAlarm = this.selectedAlarm.alarm.id;
        const data = {
          id_alarm: idAlarm,
          attending: this.actionType.attending,
          attended: this.actionType.attended,
          canceled: this.actionType.canceled,
        };

        if (data.attending) {
          data["attending_comments"] = this.comments;
          if (this.alarmLevelSelected) {
            data["id_alarm_level"] = this.alarmLevelSelected;
          }
        }

        if (this.alarmCommentSelected) {
          data["protocol"] = this.alarmCommentSelected;
        }

        if (data.attended) data["attended_comments"] = this.comments;
        if (data.attended) data["solved_comments"] = this.comments;
        if (data.canceled) data["cancel_comments"] = this.comments;

        this.loading = true;

        let token = localStorage.getItem("accessToken");

        if (this.selectedAlarm.alarm.host !== process.env.VUE_APP_BASE_URL) {
          token = this.$store.state.user.user.external_users.find(
            (external_user) => external_user.host == this.selectedAlarm.alarm.host
          ).token;
        }

        axios
          .post(`${this.selectedAlarm.alarm.host}/v1/services/alarms/update`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
              "X-Host": this.selectedAlarm.alarm.host,
              "X-localization": this.locale,
            },
          })
          .then((resp) => {
            if (data.attending) {
              this.showMessage(
                this.$t("notifications.alarm.updated_to_attending_success")
              );
              EventBus.emit("alarm:attending", data.id_alarm);
            }
            if (data.attended) {
              this.showMessage(
                this.$t("notifications.alarm.updated_to_attended_success")
              );
              EventBus.emit("alarm:attended", data.id_alarm);
            }
            if (data.canceled) {
              this.showMessage(
                this.$t("notifications.alarm.updated_to_canceled_success")
              );
              EventBus.emit("alarm:canceled", data.id_alarm);
            }

            if (this.selectedAlarm.slot && this.selectedAlarm.slot.pos) {
              store.dispatch("grid/updateSlotData", {
                pos: this.selectedAlarm.slot.pos,
                data: resp.data.data,
              });
            }

            store.dispatch("grid/removeVisited", { id: data.id_alarm, type: "alarm" });

            // store.dispatch("grid/updateAlarms");
            this.closeModal();
          })
          .catch((error) => {
            this.showMessage(
              `${this.$t("notifications.alarm.updated_error")}: ${idAlarm}`,
              "danger"
            );
            console.log("submit", error);
          })
          .finally(() => (this.loading = false));
      }
    },
    showMessage(msg, variant = "success") {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: this.$t("notifications.alarm.updated_alarm_title"),
          variant,
          icon: "BellIcon",
          text: msg,
          timeout: 5000,
        },
      });
    },
    resetDataModal() {
      store.dispatch("grid/unsetSelectedAlarm");
      this.comments = "";
      this.alarmLevelOptions = [];
      this.alarmCommentOptions = [];
      this.alarmLevelSelected = null;
      this.alarmCommentSelected = null;
    },
    closeModal() {
      this.resetDataModal();
      this.$bvModal.hide("alarm_action_modal");
    },
    onCancel() {
      this.closeModal();
    },
  },
};
</script>

<style></style>
