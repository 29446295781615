<template>
  <div>
    <p class="lead text-center font-medium-3">
      {{ $t('grid.slots.info.detection_events.lpr.title') }}
    </p>
    <tr style="display: block">
      <carousel
        :v-bind="lprMatches"
        :per-page="1"
        :adjustableHeight="true"
        paginationPosition="bottom-overlay"
        :paginationEnabled="false"
        :centerMode="true"
        paginationActiveColor="#1C3D5E"
        :navigationEnabled="true"
        @page-change="pageChange"
        class="carousel-infoslot"
        >
      
        <slide v-for="(value, key) in lprMatches" :key="`slide-${key}`">
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
            "
            v-if="value == ''"
          >
            <b-spinner variant="primary" style="margin-top: 20px;"></b-spinner>
          </div>
          <lpr-data
            v-else
            :key="`lpr-data-${key}-${value.created}`"
            :lprData="value"
            :index="key"
            :isLastOne="key === lprMatches.length - 1"
          />
        </slide>
      </carousel>
    </tr>
    <!-- <tr style="display: block">
      <td class="d-flex justify-content-center">
        <b-button
          variant="outline-primary"
          :to="`/facial_recognition/${data.personId}`"
          >{{ $t("grid.slots.info.fr.match_history") }}</b-button
        >
      </td>
    </tr> -->
  </div>
</template>
<script>
import store from "@/store";
import moment from "moment-timezone";
import { EventBus } from "@/libs/event-bus";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import LPRData from "./LPRData.vue";
import axios from "@axios";
import { BButton } from "bootstrap-vue";
import { Carousel, Slide } from "vue-carousel";

export default {
  components: {
    "lpr-data": LPRData,
    BButton,
    Carousel,
    Slide,
  },
  props: {
    lprData: Object,
  },
  data() {
    return {
      lprMatches: [],
    };
  },
  methods: {
    showMessage(title, msg, variant = "success") {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title,
          variant,
          icon: "BellIcon",
          text: msg,
          timeout: 5000,
        },
      });
    },
    pageChange(pageNumber) {
      axios
        .post(
          `${process.env.VUE_APP_BASE_URL}/v1/services/detection-events/getAlarmDataLPRMatchByIndex`,
          {
            alarmId: this.$props.lprData.id,
            index: pageNumber,
          }
        )
        .then((res) => {
          const snapshot = res.data.data;
          this.$set(this.lprMatches, pageNumber, snapshot);
        });
    },
  },

  async mounted() {
    window.Echo.private(
      `lpr-location-alarm-${this.$props.lprData.id}`
    ).listen(".create", ({ message }) => {
      this.lprMatches.push(message);
    });

    const { data } = await axios.post(
      `${process.env.VUE_APP_BASE_URL}/v1/services/detection-events/getAlarmDataLPRFirstMatch`,
      {
        alarmId: this.$props.lprData.id,
      }
    );

    const lprFirstMatch = data.data;
    const totalMatches = lprFirstMatch.total_matches;

    for (let index = 0; index < totalMatches; index++) {
      this.lprMatches[index] = "";
    }

    this.$set(this.lprMatches, 0, lprFirstMatch);
  },
  beforeDestroy() {
    window.Echo.leave(`lpr-location-alarm-${this.$props.lprData.id}`);
  },
};
</script>
<style>
.carousel-infoslot .VueCarousel-navigation-prev {
  top: 15%;
  left: 40px; 
}

.carousel-infoslot .VueCarousel-navigation-next {
  top: 15%; 
  right: 40px;
}
</style>
