import moment from 'moment-timezone'

export default class {

  static parseDate(value) {
    const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
    return `${formattedDate} (CST)`
  }

  static parseDateTimezone(value) {
    return `${value} (CST)`
  }


}
