<template>
  <div>
    <p class="lead text-center font-medium-3">
      {{ $t("grid.slots.info.fr.title") }}
    </p>
    <carousel
      :v-bind="frMatches"
      :per-page="1"
      :adjustableHeight="true"
      paginationPosition="bottom-overlay"
      :paginationEnabled="false"
      :centerMode="true"
      paginationActiveColor="#1C3D5E"
      :navigationEnabled="true"
      @page-change="pageChange"
      class="carousel-infoslot"
    >
      <slide v-for="(value, key) in frMatches" :key="`slide-${key}`">
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
          "
          v-if="value == ''"
        >
          <b-spinner variant="primary" style="margin-top: 20px"></b-spinner>
        </div>
        <fr-data
          v-else
          :key="`fr-data-${key}-${value.created}`"
          :dataMatch="value"
          :detectionEventData="detectionEventData"
          :frData="frData"
          :index="key"
          :isLastOne="key === frMatches.length - 1"
        />
      </slide>
    </carousel>

    <!-- <tr style="display: block">
      <td class="d-flex justify-content-center">
        <b-button
          variant="outline-primary"
          :to="`/facial_recognition/${data.personId}`"
          >{{ $t("grid.slots.info.fr.match_history") }}</b-button
        >
      </td>
    </tr> -->
  </div>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FRData from "./FRData.vue";
import axios from "@axios";
import { BButton } from "bootstrap-vue";
import { Carousel, Slide } from "vue-carousel";

export default {
  components: {
    "fr-data": FRData,
    BButton,
    Carousel,
    Slide,
  },
  props: {
    frData: Object,
    detectionEventData: Object
  },
  data() {
    return {
      frMatches: [],
    };
  },
  methods: {
    showMessage(title, msg, variant = "success") {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title,
          variant,
          icon: "BellIcon",
          text: msg,
          timeout: 5000,
        },
      });
    },
    pageChange(pageNumber) {
      axios
        .post(`${process.env.VUE_APP_BASE_URL}/v1/luna/getAlarmDataFRMatchByIndex`, {
          alarmId: this.$props.frData.alarm_id,
          index: pageNumber + 1,
        })
        .then((res) => {
          const snapshot = res.data.data;
          this.$set(this.frMatches, pageNumber, snapshot);
        });
    },
  },

  async mounted() {
    window.Echo.private(`fr-location-alarm-${this.$props.frData.alarm_id}`).listen(
      ".create",
      ({ message }) => {
        this.frMatches.push(message);
      }
    );
    const { data } = await axios.post(
      `${process.env.VUE_APP_BASE_URL}/v1/luna/getAlarmDataFRFirstMatch`,
      {
        alarmId: this.$props.frData.alarm_id,
      }
    );
    const snapshot = data.data;
    const totalMatches = snapshot.total_matches;

    this.$set(this.frMatches, 0, snapshot);
    for (let index = 1; index < totalMatches - 1; index++) {
      this.frMatches[index] = "";
    }
  },
  beforeDestroy() {
    window.Echo.leave(`fr-location-alarm-${this.$props.frData.alarm_id}`);
  },
};
</script>
<style lang="scss">
.carousel-infoslot {
  margin-bottom: 30px;
}

.carousel-infoslot .VueCarousel-inner {
  height: auto !important;
}

.carousel-infoslot .VueCarousel-navigation-prev {
  top: 15%;
  left: 40px;
}

.carousel-infoslot .VueCarousel-navigation-next {
  top: 15%;
  right: 40px;
}
</style>
