import moment from "moment";

export default class {

  constructor() {
    if (typeof this.setConfig() === 'object') {
      Object.assign(this, this.setConfig());
    } else {
      throw new Error('setConfig method must return an object.');
    }

  }

  setConfig() { }

  buildDate(date, format = 'dts', tz = 'Etc/GMT+6') {

    const dateFormat = (format) => {
      switch (format) {
        case 'dts':
          return 'YYYY-MM-DD HH:mm:ss';

        case 'dtst':
          return 'YYYY-MM-DDTHH:mm:ss';

        default:
          return 'YYYY-MM-DD HH:mm:ss';
      }
    }

    return moment(date).tz(tz).format(dateFormat(format));
  }

}
