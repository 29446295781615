<template>
  <div v-if="data.data.sip_call">
    <p class="lead text-center font-medium-3">SIP Calls</p>
    <tr style="display:block">
      <sip-call-data
        v-for="data in data.data.sip_call"
        :key="`sip-${data.id}`"
        :data="data"
      />
    </tr>
    <tr style="display:block">
      <!-- <th>{{ $t("grid.slots.info.actions") }}</th> -->
      <td class="d-flex justify-content-center">
        <a
          href="#"
          class="btn btn-sm btn-success mx-5"
          @click.prevent="callFromAlarmSlot(data.data.sip_call[0])"
        >
          {{ $t("grid.slots.info.sip_call.make_call") }}
        </a>
      </td>
    </tr>
  </div>
</template>
<script>
import SipCallData from "./SipCallData.vue";
import store from "@/store";
import moment from "moment-timezone";
import { EventBus } from "@/libs/event-bus";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    SipCallData,
  },
  props: {
    data: Object,
  },
  methods: {
    makeCall(data) {
      EventBus.emit(`call_device`, { id: data.callingPartyNumber });
    },
    callFromAlarmSlot(data) {
      EventBus.emit(`call_device_presaved_call`, {...data});
    },
    showMessage(title, msg, variant = "success") {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title,
          variant,
          icon: "BellIcon",
          text: msg,
          timeout: 5000,
        },
      });
    },
  },
};
</script>
<style>
</style>