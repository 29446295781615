<template>
  <div class="w-100 h-100">
    <!-- <div class="border-bottom">
      <div
        class="m-0 h4 d-flex"
      >
        <p class="w-100 pr-1 mb-0">
          <span style="font-size: small; opacity:.7; float: right;">{{ formatDateAssigned(dataMatch.created) }}</span>
          <span style="font-size: medium;">{{ dataMatch.unit.description }}</span>
          <br>
          <span style="font-size: smaller; opacity:.8;">{{ dataMatch.cameraName }}</span>
        </p>
      </div>
    </div> -->
    <div class="w-75 mx-auto">
      <div class="row my-2">
        <div class="col-12 mb-2">
          <div style="position: relative; display: inline-block; overflow: hidden">
            <div style="position: absolute; width: 100%; height: 100%; z-index: 3">
              <div
                style="
                  color: white;
                  width: 100%;
                  background-color: rgba(0, 0, 0, 0) !important;
                  text-shadow: 1px 2px #000;
                  padding: 0.5rem;
                  top: 0;
                  background: linear-gradient(
                    0deg,
                    transparent 0%,
                    rgba(0, 0, 0, 0.5) 95%
                  );
                "
              >
                <span style="display: block">{{  dataMatch ? (dataMatch.cameraName || detectionEventData.camera.name) : detectionEventData.camera.name }}</span>
                <!-- <span style="display: block">{{ dataMatch.unit.description }}</span> -->
                <span style="position: absolute; top: 0; right: 0; padding: 0.5rem">{{
                  formatDateAssigned(dataMatch ? dataMatch.created : detectionEventData.created_at)
                }}</span>
              </div>

              <div
                style="
                  position: absolute;
                  color: white;
                  width: 100%;
                  background-color: rgba(0, 0, 0, 0) !important;
                  text-shadow: 1px 2px #000;
                  padding: 0.5rem;
                  bottom: 0;
                  background: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
                "
              >
                <span style="bottom: 0">{{ data.name }}</span>
              </div>
            </div>
            <img
              class="img-thumbnail"
              :src="snapshotImgFile"
              alt=""
              @click="openImageModal(snapshotImgFile)"
              v-b-modal.image_modal
            />
          </div>
        </div>

        <div class="col-6 text-center">
          <img
            class="img-thumbnail"
            :src="enrolledProfileImgUrl"
            alt=""
            @click="openImageModal(enrolledProfileImgUrl)"
            v-b-modal.image_modal
          />
          <p class="my-0 mt-1">
            {{ $t("grid.slots.info.fr.enrolled_person") }}
          </p>
        </div>
        <div class="col-6 text-center">
          <img
            class="img-thumbnail"
            :src="portraitImgUrl"
            alt=""
            @click="openImageModal(portraitImgUrl)"
            v-b-modal.image_modal
          />
          <p class="my-0 mt-1 text-center">
            {{ $t("grid.slots.info.fr.face_detected") }}
          </p>
        </div>
      </div>
    </div>
    <table class="table table-sm" v-if="data">
      <tr>
        <th>{{ $t("grid.slots.info.fr.similarity") }}:</th>
        <td>{{ data.score_similarity ? formatPercentages(data.score_similarity) : formatPercentages(data.similarity) }}</td>
      </tr>
      <tr>
        <th>{{ $t("grid.slots.info.fr.list_name") }}:</th>
        <td>{{ data.list || 'Teltex' }}</td>
      </tr>
      <tr>
        <th>{{ $t("grid.slots.info.fr.age") }}:</th>
        <td>{{ formatNum(data.age) }}</td>
      </tr>
      <tr>
        <th>{{ $t("grid.slots.info.fr.gender") }}</th>
        <td>
          {{
            $t(
              `grid.slots.info.fr.${
                data.gender === "Hombre" || data.gender === 1 ? "genderMale" : "genderFemale"
              }`
            )
          }}
        </td>
      </tr>
      <tr>
        <th>{{ $t("grid.slots.info.fr.emotion") }}:</th>
        <td>{{ data.emotion || data.predominant_emotion }}</td>
      </tr>
      <tr>
        <th>{{ $t("grid.slots.info.fr.glasses") }}:</th>
        <td>
          {{
            $t(
              `boolean.${
                data.glasses === "no_glasses" || data.glasses === "no"
                  ? "n"
                  : "y"
              }`
            )
          }}
        </td>
      </tr>
      <tr>
        <th>{{ $t("grid.slots.info.people_analytics.top_color") }}:</th>
        <td>{{ metadata.upper_body_color }}</td>
      </tr>
      <tr>
        <th>{{ $t("grid.slots.info.people_analytics.bottom_color") }}:</th>
        <td>{{ metadata.lower_body_color }}</td>
      </tr>
      <tr>
        <th>{{ $t("grid.slots.info.people_analytics.hair_length") }}:</th>
        <td>
          {{ data.gender === "Hombre" || data.gender === 1 ? "short_hair" : "long_hair" }}
        </td>
      </tr>
      <tr>
        <th>{{ $t("grid.slots.info.people_analytics.carrying_objects") }}:</th>
        <td>
          {{
            $t(
              `boolean.${
                !metadata.accessory || metadata.accessory === "no" || metadata.accessory === "null" ? "n" : "y"
              }`
            )
          }}
        </td>
      </tr>
      <tr>
        <th>{{ $t("grid.slots.info.people_analytics.vehicle") }}:</th>
        <td>
          {{
            $t(
              `boolean.${
                !metadata.vehicle || metadata.vehicle === "no" || metadata.vehicle === "null" ? "n" : "y"
              }`
            )
          }}
        </td>
      </tr>
    </table>
    <div class="mt-1 text-center" v-if="data.personId">
      <b-button variant="outline-primary" :to="`/facial_recognition/${data.personId}`">{{
        $t("grid.slots.info.fr.match_history")
      }}</b-button>
    </div>
  </div>
</template>

<script>
import axiosIns from "axios";
import { BButton } from "bootstrap-vue";
import moment from "moment-timezone";
import store from "@/store";

const eventMsUrl = `${process.env.VUE_APP_MS_EVENTS_URL}`;

export default {
  components: {
    BButton,
  },
  props: {
    fromAlarmSlot: Boolean,
    frData: Object,
    dataMatch: Object,
    detectionEventData: Object,
    index: Number,
  },
  data() {
    return {
      isCollapseOpen: false,
      data: {
        personId: "",
        quality: {
          blurriness: "",
          dark: "",
          light: "",
          saturation: "",
        },
      },
      snapshotImgUrl: "",
      portraitImgUrl: "",
      enrolledProfileImgUrl: "",

      snapshotImgFile: "",
      portraitImgFile: "",
      enrolledProfileImgFile: "",

      metadata: {},
    };
  },
  async mounted() {
    this.snapshotImgFile = this.dataMatch ? this.dataMatch.url || ( process.env.VUE_APP_BASE_URL + '/storage' + this.frData.image) : ( process.env.VUE_APP_BASE_URL + '/storage' + this.frData.image);
    this.portraitImgUrl = this.dataMatch ? this.dataMatch.url_portraitImg || this.frData.id_luna : this.frData.id_luna;
    this.enrolledProfileImgUrl = this.dataMatch ? this.dataMatch.url_enrolledProfileImg || this.frData.avatar : '';

    this.data = {
      ...this.dataMatch, 
      ...this.frData
    };

    this.getMetadata().then(({ data }) => {
      const event = data.data;
      this.metadata = event.data;
    });
  },
  methods: {
    getMetadata() {
      return axiosIns.get(`${eventMsUrl}/find?id=${this.detectionEventData.id}`);
    },
    formatPercentages(num) {
      return num ? `${(parseFloat(num) * 100).toFixed(2)} %` : 'N/A';
    },
    formatNum(num, decimals = 0) {
      return parseFloat(num).toFixed(decimals);
    },
    formatDateAssigned(value) {
      const formattedDate = moment(value).format("DD/MM/YYYY HH:mm:ss");
      return formattedDate;
    },
    async openImageModal(imageUrl) {
      await store.dispatch("image_modal/imageUrl", imageUrl);
    },
  },
};
</script>

<style></style>
