<!-- Data for mobotix (MBX) or Motion Event (ME) alarms -->
<template>
  <div>
    <template v-if="!loading">
      <p class="lead text-center font-medium-3">Motion Event</p>
      <div class="w-75 mx-auto">
        <div class="row my-2">
          <div class="col-12 mb-2">
            <img class="img-thumbnail" :src="imageUrl" alt="" />
          </div>
          <div class="col-12 mb-2">
            <video class="w-100" :src="videoUrl" controls></video>
          </div>
        </div>
      </div>
    </template>
    <div class="pb-2 text-center" v-else>
      <b-spinner v-if="!loading" label="Spinning" variant="primary"></b-spinner>
    </div>
  </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";
import axios from "@axios";

export default {
  props: {
    idAlarm: Number | String,
  },
  components: {
    BSpinner,
  },
  data() {
    return {
      loading: true,
      data: null,
      imageUrl: null,
      videoUrl: null,
    };
  },
  mounted() {
    this.fetchData().then(({ data }) => {
      const resp = data.data;
      this.data = resp.data;
      resp.media.forEach((file) => {
        if (["image/jpeg", "image/png"].includes(file.mime_type)) {
          this.imageUrl = file.url;
        } else if (["video/mp4"].includes(file.mime_type)) {
          this.videoUrl = file.url;
        }
      });
      this.loading = false;
    });
  },
  methods: {
    fetchData() {
      const endPoint = `v1/services/detection-events/get-event-by-alarm-id/${this.idAlarm}`;
      return axios.get(endPoint);
    },
  },
};
</script>

<style></style>
