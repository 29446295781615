<template>
  <camera-widget
    v-if="
      ((this.type === this.SLOT_TYPES.CAM_PA ||
        this.type === this.SLOT_TYPES.CAM_RECORDED) &&
        $can('record_video', 'Video')) ||
      (this.type === this.SLOT_TYPES.CAM_LIVE && $can('live_video', 'Video'))
    "
    :key="key"
    :pos="pos"
    :parent-id="parentId"
    :camera-id="data.camera.camera_id"
    :camera-type="data.camera.camera_type"
    :host="data.host"
    :date-range="dateRange"
    :name-sufix="resolveSufixText()"
    :tab-info="tabInfo"
    :source="source"
    @cam-removed="onCamRemoved"
    @refresh-widget="onRefreshWidget"
  />
</template>

<script>
import { SLOT_TYPES,SOURCES } from "@/config/layoutConfig";
import moment from "moment-timezone";
import CameraWidget from "../../widgets/CameraWidget.vue";
import store from "@/store";

const ts = new Date().getTime();

export default {
  components: { CameraWidget },
  props: {
    pos: {
      type: Number | String,
    },
    data: {
      type: Object,
    },
    type: {
      type: String,
      default: SLOT_TYPES.CAM_LIVE,
    },
    parentId: {
      type: Number | String,
    },
    tabInfo: {
      type: Object,
      default: () => ({
        id: "standalone",
        type: "standalone",
      }),
    },
    source: {
      type: String,
      default: SOURCES.CAMERA
    },
    tabInfo: Object,
  },
  data() {
    return {
      SLOT_TYPES,
      key: `cam-${ts}`,
      forcedType: null,
    };
  },
  mounted() {
    this.$el.addEventListener("click", this.onClick);
  },
  beforeDestroy() {
    this.$el.removeEventListener("click", this.onClick);
  },
  methods: {
    onCamRemoved(pos) {
      this.$emit("cam-removed", pos);
    },
    resolveSufixText() {
      if ([SLOT_TYPES.CAM_PA, SLOT_TYPES.CAM_RECORDED].includes(this.type)) {
        let sufix = this.$t(`slot_types.${this.type}`);
        let region =
          this.data.region && this.data.region.acronym
            ? ` - ${this.data.region.acronym}`
            : "";

        return ``;
        return `(${sufix})${region}`;
      } else {
        let region =
          this.data.region && this.data.region.acronym
            ? ` - ${this.data.region.acronym}`
            : "";

        return `${region}`;
      }
    },
    onClick() {
      if (store.getters["round_command/getIgnoredSlot"] == this.pos) {
        store.dispatch("round_command/setIgnoredSlot", 0);
      } else {
        store.dispatch("round_command/setIgnoredSlot", this.pos);
      }
    },
    onRefreshWidget() {
      const ts = new Date().getTime();
      this.key = `cam-${ts}`;
      this.forcedType = this.SLOT_TYPES.CAM_LIVE;
    },
  },
  computed: {
    dateRange() {
      // TODO: Improve this code
      if (this.forcedType === this.SLOT_TYPES.CAM_LIVE) {
        return { start: null, end: null };
      }

      if (
        this.type === this.SLOT_TYPES.CAM_PA ||
        this.type === this.SLOT_TYPES.CAM_RECORDED
      ) {
        let camera = this.data.camera;
        let fixedDate = this.data.ts
          ? this.data.ts.replace("T", " ").replace(".000Z", "")
          : null;
        let ts = this.type === this.SLOT_TYPES.CAM_PA ? moment(fixedDate) : moment();
        let delayedMin = camera.delayed_record_minutes || "00:02:00";
        let forwardMin = camera.forward_record_minutes || "00:00:30";

        let start = ts.clone().subtract(moment.duration(delayedMin));
        let end = ts.clone().add(moment.duration(forwardMin));
        if (end.isAfter(moment())) end = ts;

        start = start.format("YYYY-MM-DD HH:mm:ss");
        end = end.format("YYYY-MM-DD HH:mm:ss");

        return { start, end };
      }

      return { start: null, end: null };
    },
  },
};
</script>

<style></style>
