import ConfigBase from "./ConfigBase";

export default class extends ConfigBase {

  setConfig() {
    return {
      dateFormat: 'YYYY-MM-DDTHH:mm:ss[.000+0000]',
      // minTimelineDate: 15, // days,
      lastMinDateRequested: 8 * 60 * 60, // 72 hrs to seconds
      minTimeToLoadNextRequest: {  // minutes
        currentZoom0: 360,
        currentZoom1: 180,
        currentZoom4: 15,
      },
      fetchNewestRecordingsTime: 30 * 1000,
      fetchDataOnDemandBackRequestTime: 12 * 60 * 60, // 12 hrs to seconds,
      fetchDataOnDemandForwardRequestTime: 36 * 60 * 60, // 36 hrs to seconds,
      goToDateHourOfDay: 12, // Hour of day 0 to 23
      goToDateMinMax: (date) => {
        const now = moment(date);
        const min = now
          .clone()
          .subtract(12, "hours")
          .startOf("day");
        const max = now.clone().endOf("day");

        return { min, max };
      }
    }
  }

}
