<template>
  <div>
    <p class="lead text-center">{{ data.description }}</p>


    <table class="table table-sm">
      <tr>
        <th><feather-icon icon="GlobeIcon" /> Connection</th>
        <td>{{ connection }}</td>
      </tr>
      <tr>
        <th><feather-icon icon="ShieldIcon" /> Tamper</th>
        <td>{{ tampered }}</td>
      </tr>
      <tr>
        <th><feather-icon icon="CrosshairIcon" /> Shot num</th>
        <td>{{ data.shot_num }}</td>
      </tr>
      <tr>
        <th><feather-icon icon="LockIcon" /> Armed</th>
        <td>{{ armed }}</td>
      </tr>
      <tr>
        <th><feather-icon icon="CheckIcon" /> Ready</th>
        <td>{{ ready }}</td>
      </tr>
      <tr>
        <th><feather-icon icon="DropletIcon" /> Fluid level</th>
        <td>{{ data.fluid_level }} %</td>
      </tr>
      <tr>
        <th><feather-icon icon="ThermometerIcon" /> Boil temp</th>
        <td>{{ data.boil_temp }} &deg;C</td>
      </tr>
      <tr>
        <th><feather-icon icon="CrosshairIcon" /> Last shot</th>
        <td>{{ data.last_shot }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
  data() {
    return {
      connection: "",
      tampered: "",
      armed: "",
      ready: "",
    };
  },
  methods: {
    setConnection() {
      if (this.data.connection === 1) {
        this.connection = "Online";
      } else {
        this.connection = "Offline";
      }
    },
    setTampered() {
      if (this.data.tampered === 0) {
        this.tampered = "Ok";
      } else {
        this.tampered = "WARNING";
      }
    },
    setArmed() {
      if (this.data.armed === 1) {
        this.armed = "Yes";
      } else {
        this.armed = "No";
      }
    },
    setReady() {
      if (this.data.ready === 1) {
        this.ready = "Ok";
      } else {
        this.ready = "WARNING";
      }
    },


    setData() {
      this.setConnection();
      this.setTampered();
      this.setArmed();
      this.setReady();
    },
  },
  created() {
    this.setData();
  },
};
</script>

<style></style>
