<template>
  <div>

    <div class="w-75 mx-auto">
      <div class="row my-2" v-if="data">
        <div class="col-6 text-center">
          <img 
            class="img-thumbnail" 
            :src="generalImageUrl" alt="" 
            @click="openImageModal(generalImageUrl)" 
            v-b-modal.image_modal 
          />
          <p class="my-0 mt-1">{{ $t('grid.slots.info.detection_events.labels.overall_picture') }}</p>
        </div>
        <div class="col-6 text-center">
          <img 
            class="img-thumbnail" 
            :src="imgUrl" alt="" 
            @click="openImageModal(imgUrl)" 
            v-b-modal.image_modal 
          />
          <p class="my-0 mt-1 text-center">{{ $t('grid.slots.info.detection_events.labels.plate') }}</p>
        </div>
      </div>
    </div>
    <table class="table table-sm" v-if="data">
      <tr>
        <th>{{ $t('grid.slots.info.detection_events.labels.plate') }}:</th>
        <td>{{ data.plate_number }}</td>
      </tr>
      <tr>
        <th>{{ $t('grid.slots.info.detection_events.labels.camera_name') }}:</th>
        <td>{{ data.camera_name }}</td>
      </tr>
      <!-- <tr>
        <th>{{ $t('grid.slots.info.detection_events.labels.city') }}:</th>
        <td>{{ data.region }}</td>
      </tr> -->
      <tr>
        <th>{{ $t('grid.slots.info.detection_events.labels.date') }}:</th>
        <td>{{ lprData.event_created_at }}</td>
      </tr>
      <tr>
        <th>{{ $t('grid.slots.info.detection_events.labels.speed') }}:</th>
        <td>{{ data.speed ? formatNum(data.speed) : 'N/A' }}</td>
      </tr>
      <tr>
        <th>{{ $t('grid.slots.info.detection_events.labels.brand') }}:</th>
        <td>{{ data.marker }}</td>
      </tr>
      <tr>
        <th>{{ $t('grid.slots.info.detection_events.labels.model') }}:</th>
        <td>{{ data.model }}</td>
      </tr>
      <tr>
        <th>{{ $t('grid.slots.info.detection_events.labels.color') }}:</th>
        <td>{{ data.color }}</td>
      </tr>
      <!-- <tr>
        <th>{{ $t('grid.slots.info.detection_events.labels.class') }}:</th>
        <td>{{ data.v_class }}</td>
      </tr> -->
      <tr>
        <th>{{ $t('grid.slots.info.detection_events.labels.list') }}:</th>
        <td>{{ data.list }}</td>
      </tr>
      <tr>
        <th>{{ $t('grid.slots.info.detection_events.labels.confidence') }}:</th>
        <td>{{ data.confidence }} %</td>
      </tr>
    </table>
  </div>
</template>

<script>
import store from "@/store";

export default {
  props: {
    lprData: Object,
  },
  data() {
    return {
      data: null,
      generalImageUrl: "",
      imgUrl: "",
    };
  },
  async mounted() {
    this.data = this.lprData.data;
    this.generalImageUrl = this.lprData.general_image_url;
    this.imgUrl = this.lprData.image_url;
  },
  methods: {
    formatNum(num, decimals = 0) {
      return parseFloat(num).toFixed(decimals);
    },
    async openImageModal(imageUrl){
      await store.dispatch("image_modal/imageUrl", imageUrl );
    }
  },
};
</script>

<style></style>
