<template>
  <div>
    <div class="lead text-center">
      <span class="mr-25">
        {{ data.name || data.camera_name }}
      </span>
      <i class="bg-success camera-status" v-if="data.online"></i>
      <i class="bg-danger camera-status" v-else></i>
    </div>
    <table class="table table-sm">
      <tr>
        <th>{{ $t('grid.slots.info.camera.review_start') }}</th>
        <td>{{ data.start_time_event || "-" }}</td>
      </tr>
      <tr>
        <th>{{ $t('grid.slots.info.camera.review_end') }}</th>
        <td>{{ data.end_time_event || "-" }}</td>
      </tr>
      <tr>
        <th>{{ $t('grid.slots.info.camera.events_review') }}</th>
        <td>{{ data.check_events ? "Activado" : "Desactivado" }}</td>
      </tr>
      <tr v-if="data.tenants">
        <th>Tenant(s)</th>
        <td>{{ data.tenants.length ? parseTenants(data.tenants) : "-" }}</td>
      </tr>
      <tr v-if="data.center">
        <th>{{ $t('grid.slots.info.camera.center') }}</th>
        <td>{{ data.center ? data.center.name : "-" }}</td>
      </tr>
      <tr v-if="data.sector">
        <th>{{ $t('grid.slots.info.camera.sector') }}</th>
        <td>{{ data.sector ? data.sector.name : "-" }}</td>
      </tr>
      <tr>
        <th>{{ $t('pre_alarm') }}</th>
        <td>
          <table class="table table-sm table-borderless">
            <tr>
              <th>{{ $t('grid.slots.info.camera.previous_time') }}:</th>
              <td>{{ data.delayed_record_minutes }}</td>
            </tr>
            <tr>
              <th>{{ $t('grid.slots.info.camera.later_time') }}:</th>
              <td>{{ data.forward_record_minutes }}</td>
            </tr>
          </table>
        </td>
      </tr>

      <tr v-if="data.reseller">
        <th>Reseller</th>
        <td>
          <table class="table table-sm table-borderless">
            <tr>
              <th>{{ $t('grid.slots.info.camera.reseller.name') }}:</th>
              <td>{{ data.reseller.firstName }}</td>
            </tr>
            <tr>
              <th>E-mail:</th>
              <td>{{ data.reseller.contactEmail }}</td>
            </tr>
            <tr>
              <th>{{ $t('grid.slots.info.camera.reseller.company') }}:</th>
              <td>{{ data.reseller.companyName }}</td>
            </tr>
          </table>
        </td>
      </tr>

      <template v-if="data.bridge && data.bridge_account">
        <tr>
          <th>Bridge</th>
          <td>
            <table class="table table-sm table-borderless">
              <tr>
                <th>{{ $t('grid.slots.info.camera.bridge.account_id') }}:</th>
                <td>{{ data.bridge.account_id }}</td>
              </tr>
              <tr>
                <th>{{ $t('grid.slots.info.camera.bridge.bridge_id') }}:</th>
                <td>{{ data.bridge.bridge_id }}</td>
              </tr>
              <tr>
                <th>{{ $t('grid.slots.info.camera.bridge.device_name') }}:</th>
                <td>{{ data.bridge.device_name }}</td>
              </tr>
            </table>
          </td>
        </tr>

        <tr>
          <th>Bridge Account</th>
          <td>
            <table class="table table-sm table-borderless">
              <tr>
                <th>{{ $t('grid.slots.info.camera.bridge_account.account_id') }}:</th>
                <td>{{ data.bridge_account.account_id }}</td>
              </tr>
              <tr>
                <th>{{ $t('grid.slots.info.camera.bridge_account.name') }}:</th>
                <td>{{ data.bridge_account.name }}</td>
              </tr>
            </table>
          </td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
  methods: {
    parseTenants(tenants) {
      return tenants.map((t) => t.name).join(",");
    },
  },
};
</script>

<style lang="scss" scoped>
.camera-status {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
}
</style>
