<template>
  <div>
    <b-row align-v="center" class="d-flex align-items-center">
      <b-col cols="6" class="mb-2">
        <label class="text-capitalize">
          {{ $t("alarm.status") }}
        </label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="statusFilter"
          :options="[
            { label: $t('alarm.waiting'), value: 'waiting' },
            { label: $t('alarm.attending'), value: 'attending' },
            { label: $t('alarm.canceled'), value: 'canceled' },
            { label: $t('alarm.attended'), value: 'attended' },
          ]"
          class="w-100 text-capitalize"
          multiple
          append-to-body
          :calculate-position="withPopper"
          :reduce="(val) => val.value"
          @input="(val) => $emit('update:statusFilter', val)"
        />
      </b-col>
      <b-col cols="6" class="mb-2">
        <label class="text-capitalize">
          {{ $tc("tenants.title", 2) }}
        </label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="tenantFilter"
          :options="tenantOptions"
          class="w-100"
          multiple
          append-to-body
          :calculate-position="withPopper"
          :reduce="(val) => val.value"
          @input="(val) => $emit('update:tenantFilter', val)"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6" class="mb-2">
        <label class="text-capitalize">
          {{ $tc("unit.title", 2) }}
        </label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="unitFilter"
          :options="unitOptions"
          class="w-100"
          multiple
          append-to-body
          :calculate-position="withPopper"
          :reduce="(val) => val.value"
          @input="(val) => $emit('update:unitFilter', val)"
        />
      </b-col>
      <b-col cols="6" class="mb-2">
        <label class="text-capitalize">
          {{ $tc("camera.title", 2) }}
        </label>
        <v-select
          v-model="cameraArray"
          :options="formattedOptions"
          :filterable="false"
          :clearable="false"
          :reduce="(val) => val.value"
          :calculate-position="withPopper"
          @search="onSearch"
          @option:selected="optionSelect"
          @option:deselected="optionDeselect"
          append-to-body
          multiple
        >
          <template #list-footer>
            <li class="pagination">
              <b-button
                variant="outline-primary"
                size="sm"
                :disabled="!hasPrevPage"
                @click="prevPage"
              >
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </b-button>
              <b-button
                variant="outline-primary"
                size="sm"
                :disabled="!hasNextPage"
                @click="nextPage"
              >
                <feather-icon icon="ChevronRightIcon" size="18" />
              </b-button>
            </li>
          </template>
        </v-select>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6" class="mb-2">
        <label class="text-capitalize">
          {{ $t("alarm.alarm_type") }}
        </label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="alarmTypeFilter"
          :options="alarmTypeOptions"
          class="w-100"
          multiple
          append-to-body
          :calculate-position="withPopper"
          :reduce="(val) => val.value"
          @input="(val) => $emit('update:alarmTypeFilter', val)"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6" class="mb-2" v-if="$can('filter_datetime', 'Alarm')">
        <label class="text-capitalize">
          {{ $t("alarm.start_date_filter") }}
        </label>
        <flat-pickr
          class="form-control"
          :config="{
            enableTime: true,
            dateFormat: 'Y-m-d H:i:s',
            time_24hr: true,
          }"
          :value="startDatetime"
          @on-change="(selectedDates, val) => $emit('update:startDatetime', val)"
        />
      </b-col>
      <b-col cols="6" class="mb-2" v-if="$can('filter_datetime', 'Alarm')">
        <label class="text-capitalize">
          {{ $t("alarm.end_date_filter") }}
        </label>
        <flat-pickr
          class="form-control"
          :config="{
            enableTime: true,
            dateFormat: 'Y-m-d H:i:s',
            time_24hr: true,
          }"
          :value="endDatetime"
          @on-change="(selectedDates, val) => $emit('update:endDatetime', val)"
        />
      </b-col>
      <!-- <b-col cols="4" md="4" v-if="$can('filter_datetime', 'Alarm')" class="mb-md-0 mb-2">
        <label class="text-capitalize">
          {{ $t('alarm.date_filter') }}
        </label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="dateFilter"
          :options="[
              { label: $t('date.created_at'), value: 'created'},
              // { label: $t('date.updated_at'), value: 'updated'},
              { label: $t('alarm.waiting'), value: 'waiting'},
              { label: $t('alarm.attending'), value: 'attending'},
              { label: $t('alarm.canceled'), value: 'canceled'},
              { label: $t('alarm.attended'), value: 'attended'},
            ]"
          class="w-100 text-capitalize"
          multiple
          :reduce="(val) => val.value"
          @input="(val) => $emit('update:dateFilter', val)"
        />
      </b-col> -->
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BBadge,
} from "bootstrap-vue";
import { debounce } from "lodash";
import axios from "@axios";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { ref, computed, watch, onMounted } from "@vue/composition-api";
import popperHelper from "@/libs/popper-helper";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BFormCheckbox,
    vSelect,
    BBadge,
    flatPickr,
  },
  props: {
    alarmTypeFilter: {
      type: Array,
      default: null,
    },
    alarmTypeOptions: {
      type: Array,
      default: null,
    },
    tenantOptions: {
      type: Array,
      default: null,
    },
    tenantFilter: {
      type: Array,
      default: null,
    },
    cameraFilter: {
      type: Array,
      default: null,
    },
    unitFilter: {
      type: Array,
      default: null,
    },
    unitOptions: {
      type: Array,
      default: null,
    },
    statusFilter: {
      type: Array,
      default: null,
    },
    startDatetime: {
      type: String,
      default: null,
    },
    endDatetime: {
      type: String,
      default: null,
    },
    dateFilter: {
      type: Array,
      default: null,
    },
  },
  setup(props, { emit }) {
    const cameraArray = ref([]);
    const currentPage = ref(1);
    const search = ref(null);
    const meta = ref(null);
    const limit = ref(8);
    const options = ref([]);
    const camerasProxy = ref([]);

    const fetchCameraData = () => {
      return new Promise((resolve, reject) => {
        let params = {
          q: search.value,
          max: limit.value,
          "page[n]": currentPage.value,
          sortBy: "id",
          sortDesc: true,
        };
        axios("v1/proxy_camera?deviceType=camera", { params })
          .then((response) => {
            meta.value = response.data.meta.last_page;
            options.value = response.data.data;
            resolve();
          })
          .catch(() => {
            console.log("SelectPaginateAjax: Error obteniendo la información");
            reject();
          });
      });
    };

    const onSearch = debounce(async (query, loading) => {
      if (query.length) {
        loading(true);
        search.value = query;
        await fetchCameraData();
        loading(false);
      } else {
        currentPage.value = 1;
        search.value = "";
        fetchCameraData(query.value);
      }
    }, 500);

    const hasNextPage = computed(() => {
      if (meta.value) {
        return currentPage.value + 1 <= meta.value;
      }
      return false;
    });
    const formattedOptions = computed(() => {
      return options.value.map((camera_proxy) => ({
        label: camera_proxy.camera.name == null ? null : camera_proxy.camera.name,
        value: camera_proxy.id,
      }));
    });
    const hasPrevPage = computed(() => {
      return currentPage.value - 1 > 0;
    });

    const nextPage = () => {
      currentPage.value += 1;
    };
    const prevPage = () => {
      currentPage.value--;
    };

    onMounted(() => {
      fetchCameraData().then(() => {
        if (props.cameraFilter) {
          cameraArray.value = [...props.cameraFilter];
        }
      });
    });

    const optionSelect = (selected) => {
      const selectedData = selected.map((camera_proxy) => camera_proxy.value);
      emit("updateCameraFilter", selectedData);
    };

    const optionDeselect = (deselected) => {
      const deselectedData = cameraArray.value.filter(
        (camera_proxy) => camera_proxy.value != deselected.value
      );
      emit("updateCameraFilter", deselectedData);
    };

    watch([currentPage], () => {
      fetchCameraData();
    });

    watch(
      () => props.cameraFilter,
      (val) => {
        if (!val) cameraArray.value = [];
      }
    );

    return {
      formattedOptions,
      hasPrevPage,
      hasNextPage,
      prevPage,
      nextPage,
      currentPage,
      meta,
      search,
      onSearch,
      fetchCameraData,
      options,
      optionSelect,
      optionDeselect,
      camerasProxy,
      cameraArray,

      withPopper: popperHelper.withPopper,
    };
  },
};
</script>
