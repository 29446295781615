<template>
  <div class="info-slot-container">
    <!-- ================= Alarm ================= -->
    <div class="p-2" v-if="source === 'alarm' || source === 'alarm_view'">
      <div class="mb-2">
        <p class="lead text-center font-medium-3 mb-2">
          <span v-html="`${data.data.unit.description} | ${data.data.alarm_type}`"></span>
        </p>
        <table class="table table-sm">
          <tr>
            <th>{{ $t("alarm.alarm_id") }}</th>
            <td>{{ data.data.id }}</td>
          </tr>
          <tr>
            <th>{{ $t("alarm.status") }}</th>
            <td>{{ alarmStatus }}</td>
          </tr>
          <tr>
            <th>{{ $t("alarm.alarm_type") }}</th>
            <td>{{ data.data.alarm_type }}</td>
          </tr>
          <tr>
            <th class="text-capitalize">{{ $t("date.created_at") }}</th>
            <td>
              {{ dateParser.parseDateTimezone(data.data.created_format_2) }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("grid.slots.info.unit") }}</th>
            <td v-html="data.data.unit.description"></td>
          </tr>
          <tr v-if="data.data.unit.address">
            <th>{{ $t("grid.slots.info.address") }}</th>
            <td>
              {{ data.data.unit.address }}
              {{ data.data.unit.exterior_number }},
              {{ data.data.unit.municipality.name }},
              {{ data.data.unit.suburb }}
            </td>
          </tr>
          <tr v-if="data.data.tenant">
            <th class="text-capitalize">{{ $tc("tenants.title", 1) }}</th>
            <td>
              <ul class="pl-1">
                <li>
                  {{ data.data.tenant.name }}
                </li>
                <li>
                  <div v-html="data.data.tenant.description"></div>
                </li>
              </ul>
            </td>
          </tr>
          <tr v-if="['waiting', 'attending'].includes(data.data.alarm_status)">
            <th>{{ $t("grid.slots.info.actions") }}</th>
            <td>
              <a
                href="#"
                class="btn btn-sm btn-success py-25 mr-25"
                @click="
                  takeCall(
                    userData,
                    data.data.sip_call[0].sessionId,
                    data.data.sip_call[0].callingPartyNumber,
                    data
                  )
                "
                v-if="
                  data.data.sip_call[0]
                    ? data.data.sip_call[0].state === 'waiting'
                      ? true
                      : false
                    : false
                "
              >
                Contestar
              </a>
              <a
                href="#"
                class="btn btn-sm btn-success py-25 mr-25"
                @click.prevent="onClickUpdateAlarmStatus('waiting')"
                v-if="['waiting', 'attending'].includes(data.data.alarm_status) && $can('update_attention', 'Alarm')"
              >
                {{ $t("grid.slots.info.btns.confirmed") }}
              </a>
              <a
                href="#"
                class="btn btn-sm btn-success py-25 mr-25"
                @click.prevent="onClickUpdateAlarmStatus('attending')"
                v-if="['waiting', 'attending'].includes(data.data.alarm_status) && $can('update_attend', 'Alarm')"
              >
                {{ $t("grid.slots.info.btns.attended") }}
              </a>
              <a
                href="#"
                class="btn btn-sm btn-danger py-25"
                @click.prevent="onClickUpdateAlarmStatus('canceled')"
                v-if="['waiting', 'attending'].includes(data.data.alarm_status) && $can('update_cancel', 'Alarm')"
              >
                {{ $t("grid.slots.info.btns.cancel") }}
              </a>
            </td>
          </tr>
        </table>
      </div>

      <div class="mb-2">
        <p class="lead text-center font-medium-3">
          {{ $t("grid.slots.info.unit") }}
        </p>
        <table class="table table-sm">
          <tr>
            <th>{{ $t("grid.slots.info.description") }}</th>
            <td>{{ data.data.unit.description }}</td>
          </tr>
          <tr>
            <th>{{ $t("grid.slots.info.company") }}</th>
            <td>{{ data.data.unit.company }}</td>
          </tr>
          <tr>
            <th>{{ $t("grid.slots.info.latitude") }}</th>
            <td>{{ data.data.unit_coordinates.lat }}</td>
          </tr>
          <tr>
            <th>{{ $t("grid.slots.info.longitude") }}</th>
            <td>{{ data.data.unit_coordinates.lng }}</td>
          </tr>
          <tr>
            <th>{{ $t("grid.slots.info.address") }} 1</th>
            <td>{{ data.data.unit.address_1 }}</td>
          </tr>
          <tr>
            <th>{{ $t("grid.slots.info.address") }} 2</th>
            <td>{{ data.data.unit.address_2 }}</td>
          </tr>
          <tr>
            <th>{{ $t("grid.slots.info.exterior_number") }}</th>
            <td>{{ data.data.unit.exterior_number }}</td>
          </tr>
          <tr>
            <th>{{ $t("grid.slots.info.suburb") }}</th>
            <td>{{ data.data.unit.suburb }}</td>
          </tr>
          <tr>
            <th>{{ $t("grid.slots.info.municipality") }}</th>
            <td>
              {{ data.data.unit.municipality ? data.data.unit.municipality.name : "" }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("grid.slots.info.postal_code") }}</th>
            <td>{{ data.data.unit.postal_code }}</td>
          </tr>
          <tr>
            <th>{{ $t("grid.slots.info.country") }}</th>
            <td>
              {{ data.data.unit.country ? data.data.unit.country.name : "" }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("grid.slots.info.region") }}</th>
            <td>
              {{ data.data.unit.region ? data.data.unit.region.name : "" }}
            </td>
          </tr>
        </table>
      </div>

      <div class="mb-2">
        <p class="lead text-center font-medium-3">
          {{ $t("grid.slots.info.tracing") }}
        </p>
        <table class="table table-sm">
          <tr v-if="data.data.protocol">
            <th>{{ $t("grid.slots.info.protocol") }}</th>
            <td>{{ data.data.protocol }}</td>
          </tr>
          <tr v-if="data.data.attending_comments">
            <th>{{ $t("grid.slots.info.attending_comments") }}</th>
            <td>{{ data.data.attending_comments }}</td>
          </tr>
          <tr v-if="data.data.solved_comments">
            <th>{{ $t("grid.slots.info.solved_comments") }}</th>
            <td>{{ data.data.solved_comments }}</td>
          </tr>
          <tr v-if="data.data.cancel_comments">
            <th>{{ $t("grid.slots.info.cancel_comments") }}</th>
            <td>{{ data.data.cancel_comments }}</td>
          </tr>
          <tr v-if="data.data.alarm_log">
            <th>{{ $t("grid.slots.info.status") }}</th>
            <td>
              <p v-for="(log, index) in data.data.alarm_log" :key="index">
                {{
                  log.attending
                    ? dateParser.parseDate(log.created) + "| Sent to attention by: "
                    : ""
                }}
                {{
                  log.canceled
                    ? dateParser.parseDate(log.created) + "|  Canceled by: "
                    : ""
                }}
                {{
                  log.attended ? dateParser.parseDate(log.created) + "| Attended by: " : ""
                }}
                {{ log.user ? log.user.email : "" }}
              </p>
            </td>
          </tr>
        </table>
      </div>

      <div>
        <p class="lead text-center font-medium-3">C5</p>
        <table class="table table-sm">
          <tr>
            <th>Folio CAD</th>
            <td>{{ data.data.cad_number || "-" }}</td>
          </tr>
          <tr>
            <th>{{ $t("grid.slots.info.status") }}</th>
            <td>{{ data.data.status_cad || "-" }}</td>
          </tr>
          <tr>
            <th>{{ $t("grid.slots.info.data") }}</th>
            <td>{{ data.data.request || "-" }}</td>
          </tr>
        </table>
      </div>

      <!-- ================= Smart Detection ================= -->
      <sd-data
        v-if="data.data.alarm_acronym === 'SD'"
        :key="`sd-${data.data.id}`"
        :sd-id="data.data.smart_detection_id"
        :host="data.data.host"
      />

      <!-- ================= Facil Recognition ================= -->
      <fr-matches
        v-if="data.data.alarm_acronym === 'FR'"
        :key="`fr-${data.data.id}`"
        :detection-event-data="data.data.detection_event_data"
        :fr-data="data.data.facial_recognition"
      />

      <!-- ================= LPR ================= -->
      <lpr-matches
        v-if="data.data.alarm_acronym === 'LPR'"
        :key="`lpr-${data.data.id}`"
        :lpr-data="data.data"
      />

      <!-- ================= LOIT ================= -->
      <!-- <loit-data
        v-if="data.data.alarm_acronym === 'LOIT'"
        :key="`loit-${data.data.id}`"
        :loit-data="data.data.loit_data"
      /> -->

      <detection-event-data
        v-if="
          data.data.detection_event_data &&
          !['LPR', 'MBX', 'ME'].includes(data.data.alarm_acronym) &&
          data.data.detection_event_data.provider != 'luna'
        "
        :key="`detectdata-${data.data.id}-${dataVersion}`"
        :detection-event-data="data.data.detection_event_data"
        :camera_data="data.data.detection_event_data.camera_data"
      />

      <!-- ================= SIP CALLS ================= -->
      <sip-calls
        v-if="data.data.sip_call && data.data.sip_call.length"
        :key="`sip-${data.data.id}-${dataVersion}`"
        :data="data"
      />

      <!-- v-for="data in data.data.sip_call" -->

      <!-- ================= ANALYTIC RECORD ================= -->
      <!-- <ar-data
        v-if="data.data.analytic_record ? true : false"
        :key="`analytic-${data.data.id}`"
        :data="data.data.analytic_record"
      /> -->

      <!-- ================= ANALYTIC RECORD ================= -->
      <ar-matches
        v-if="data.data.analytic_record ? true : false"
        :key="`analytic-${data.data.id}-${dataVersion}`"
        :data="data.data.analytic_record"
        :totalMatches="data.data.analytic_record_count"
      />

      <!-- ================= Workflow Info ================= -->
      <workflow-log :key="`wlog-${data.data.id}-${dataVersion}`" :data="data.data" />

      <!-- ================= Action Plan Info ================= -->
      <action-plan-log :key="`aplan-${data.data.id}-${dataVersion}`" :data="data.data" />

      <!-- ================= Incident Log ================= -->
      <incident-data
        v-if="data.data.incident_log"
        :key="`incident-${data.data.id}-${dataVersion}`"
        :incident="data.data.incident_log"
      />
    </div>

    <!-- ================= Unit ================= -->
    <unit-data
      class="p-1"
      v-if="[SOURCES.UNIT, SOURCES.SUBSYSTEM_TREE].includes(source)"
      :key="data.data.id"
      :data="data.data"
    />

    <!-- ================= Camera ================= -->
    <camera-data class="p-2" v-if="source === 'camera'" :data="data.data.camera" />

    <!-- ================= Swift sensors ================= -->
    <swift-sensor-data
      class="p-1"
      v-if="data.data.swift_sensor"
      :key="`swift-sensor-${data.data.id}-${dataVersion}`"
      :data="data.data.swift_sensor"
    />

    <swift-sensor-data
      class="p-1"
      v-if="
        source === SOURCES.SUBSYSTEM_TREE_SENSOR &&
        data.data.sensor_type === 'swift_sensor'
      "
      :key="`swift-sensor-${data.data.id}-${dataVersion}`"
      :data="data.data"
    />

    <!-- ================= Actuators ================= -->
    <actuator-data
      class="p-1"
      v-if="[SOURCES.ACTUATOR].includes(source)"
      :key="`actuator-${data.data.id}-${dataVersion}`"
      :data="data.data.data"
    />

    <!-- ================= Generic Devices ================= -->
    <generic-device-data
      class="p-1"
      v-if="[SOURCES.GENERIC_DEVICE].includes(source)"
      :key="`generic-device-${data.data.id}-${dataVersion}`"
      :data="data.data.data"
    />

    <!-- ================= Mobotix ================= -->
    <mbx-data
      class="p-1"
      v-if="data.data.alarm_acronym === 'MBX' || data.data.alarm_acronym === 'ME'"
      :key="`mbx-${data.data.id}-${dataVersion}`"
      :id-alarm="data.data.id"
    />
  </div>
</template>

<script>
import SDData from "./SDData.vue";
import FRMatches from "./FRMatches/Index.vue";
import ARMatches from "./ARMatches/Index.vue";
import LPRMatches from "./LPRMatches/Index.vue";
import UnitData from "./UnitData.vue";
import CameraData from "./CameraData.vue";
import ARData from "./ARData.vue";
import { SLOT_TYPES, SOURCES } from "@/config/layoutConfig";
import store from "@/store";
import WorkflowLog from "./WorkflowLog.vue";
import ActionPlanLog from "./ActionPlanLog.vue";
import SipCalls from "./SipCalls/Index.vue";
import IncidentData from "./IncidentData.vue";
import ActuatorData from "./ActuatorData";
import GenericDeviceData from "./GenericDeviceData.vue";
import SwiftSensorData from "./SwiftSensorData";
import MbxData from "./MbxData";
// import LOITData from "./LOITData.vue";
import DetectionEventData from "./DetectionEventData.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axiosIns from "axios";
import LayoutApi from "@/libs/LayoutApi";
import { EventBus } from "@/libs/event-bus";
import dateParser from "@/libs/date-parser";

const layoutApi = new LayoutApi(store);

export default {
  components: {
    "sd-data": SDData,
    "fr-matches": FRMatches,
    "ar-matches": ARMatches,
    "lpr-matches": LPRMatches,
    // "loit-data": LOITData,
    "detection-event-data": DetectionEventData,
    "ar-data": ARData,
    "mbx-data": MbxData,
    UnitData,
    IncidentData,
    CameraData,
    SipCalls,
    WorkflowLog,
    ActionPlanLog,
    ActuatorData,
    GenericDeviceData,
    SwiftSensorData,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    tabInfo: Object,
  },
  data() {
    return {
      SOURCES,
      idAlarm: null,
      dataVersion: 1,
      dateParser
    };
  },
  created() {
    this.idAlarm = this.data.data.id;
  },
  mounted() {
    this.setWebsocket();
    if (this.tabInfo) {
      EventBus.on(`timeline:${this.tabInfo.id}:change_event`, this.changeData);
    }
  },
  beforeDestroy() {
    window.Echo.leave(`alarm-${this.idAlarm}`);
    if (this.tabInfo) {
      EventBus.off(`timeline:${this.tabInfo.id}:change_event`, this.changeData);
    }
  },
  computed: {
    userData() {
      return store.state.user.user;
    },
    source() {
      return this.data.data.in_app_source;
    },
    alarmStatus() {
      switch (this.data.data.alarm_status) {
        case "waiting":
          return "Incoming";
        case "attending":
          return "Attending";
        case "attended":
          return "Attended";
        case "canceled":
          return "Canceled";
      }
    },
  },
  methods: {
    async onClickUpdateAlarmStatus(status) {
      const action = (status) => {
        switch (status) {
          case "waiting":
            return "confirmed";
          case "attending":
            return "attended";
          case "canceled":
            return "canceled";
        }
      };

      await store.dispatch("grid/setSelectedAlarm", {
        action: action(status),
        alarm: this.data.data,
        slot: { pos: this.data.pos },
      });
      this.$root.$emit("bv::show::modal", "alarm_action_modal");
    },
    showMessage(title, msg, variant = "success") {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title,
          variant,
          icon: "BellIcon",
          text: msg,
          timeout: 5000,
        },
      });
    },
    takeCall(userData, sessionId, callingPartyNumber, alarm) {
      alarm = alarm.data;
      let deviceInterphone = userData.device_interphone;
      store
        .dispatch("sip/takeCall", {
          sessionId,
          user: deviceInterphone.device_id,
          presavedCaller: callingPartyNumber,
          alarm,
        })
        .then((response) => {
          this.showMessage(response.message, "", "success");
        })
        .catch((response) => {
          if (response.response.status == 424) {
            this.showMessage(response.response.data.message, "", "danger");
          }
        })
        .finally(() => {});
    },
    setWebsocket() {
      window.Echo.leave(`alarm-${this.idAlarm}`);
      window.Echo.private(`alarm-${this.idAlarm}`).listen(".updated", () =>
        this.updateAlarmData()
      );
      setTimeout(() => {
        if (this.dataVersion === 1) {
          this.updateAlarmData();
        }
      }, 4000);
    },
    async updateAlarmData() {
      if (this.data.data.refresh_link) {
        const headers = this.requestConfig();
        const data = await axiosIns.get(this.data.data.refresh_link, { headers });
        layoutApi.updateSlotData(this.data.pos, SLOT_TYPES.INFO, data.data);
        this.dataVersion += 1;
      }
    },
    requestConfig() {
      const token = localStorage.getItem("accessToken");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "X-localization": store.getters["user/getPreferences"].lang,
      };

      return headers;
    },
    changeData(evt) {
      this.data.data = evt;
      this.dataVersion += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.info-slot-container {
  max-height: 100%;
  overflow: auto;
}
</style>
