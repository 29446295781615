<template>
  <div>
    <b-row v-if="showFilters">
      <b-col cols="6">
        <Breadcrumb
          :nameNav="nameNav"
          :itemFilter="itemFilter"
          :searchQuery="searchQuery"
          @itemFilterSearch="addItemFilterSearch"
        />
      </b-col>
      <b-col cols="6" class="pr-3 my-2 text-right" v-if="showFilters">
        <div class="d-flex justify-content-end">
          <!--Icon Filters -->
          <div>
            <button-filters
              @show-modal="$root.$emit('bv::show::modal', $event)"
              :filters-count="filtersCount"
              @clear-search-filters="clearSearchFilters"
            />
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- Filters -->
    <filter-modal
      @action="buildFilters()"
      @clear="clearSearchFilters()"
      v-if="showFilters"
    >
      <template v-slot:main-filters>
        <filters
          :camera-filter.sync="cameraFilter"
          @updateCameraFilter="handleCameraFilterUpdate"
          :unit-filter.sync="unitFilter"
          :unit-options="unitOptions"
          :tenant-filter.sync="tenantFilter"
          :tenant-options="tenantOptions"
          :alarm-type-filter.sync="alarmTypeFilter"
          :alarm-type-options="alarmTypeOptions"
          :status-filter.sync="statusFilter"
          :start-datetime.sync="startDatetimeFilter"
          :end-datetime.sync="endDatetimeFilter"
          :date-filter.sync="dateFilter"
        />
      </template>
    </filter-modal>

    <toastification-loading-content ref="loadingToast" />

    <b-card-group deck :class="{ 'mx-2': showFilters, 'm-0': !showFilters }">
      <!-- Table Container Card -->
      <b-card no-body class="m-0" v-if="!hiddenTableGrid">
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label class="text-capitalize">{{ $t("table.show") }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label class="text-capitalize">{{ $t("table.entries") }}</label>
            </b-col>

            <!-- Search -->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('table.search') + '...'"
                />
                <b-button
                  v-if="$can('export_history', 'Alarm')"
                  @click="exportExcel()"
                  variant="success"
                  class="text-capitalize ml-1"
                  type="button"
                  :disabled="disableExport"
                >
                  <feather-icon icon="DownloadCloudIcon" size="15" />
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          ref="refListTable"
          class="position-relative"
          :items="fetchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :empty-text="$t('no_record_found')"
          :sort-desc.sync="isSortDirDesc"
          @row-selected="onRowSelected"
          select-mode="single"
          selectable
        >
          <template #head()="data">
            <span> {{ $tc(data.label, 1) }}</span>
          </template>

          <!-- Column: User -->
          <template #cell(alarm_status)="data">
            <b-media vertical-align="center">
              <template #aside>
                <div
                  :style="`background: ${data.item.alarm_level.color};
                            width: 1.5rem;
                            height: 1.5rem;
                            border-radius: 1rem;
                            text-align: center;`"
                ></div>
              </template>
              <strong>{{ data.item.unit ? data.item.unit.description : "" }}</strong>
              <br />
              <small>{{ data.item.alarm_status }}</small>
            </b-media>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted text-capitalize">
                {{
                  $t("table.showing", {
                    from: dataMeta.from,
                    to: dataMeta.to,
                    of: dataMeta.of,
                  })
                }}
              </span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <Pagination
                :totalList="totalList"
                :currentPage="currentPage"
                @updatePagination="update"
                :perPage="perPage"
              >
              </Pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>

       <!-- Modal -->
      <b-card
        class="mr-0"
        :class="!showFilters ? 'showScroll ml-0' : 'sticky-card ml-2'"
        v-if="selectedObject || loadingSelected"
        no-body
      >
        <feather-icon
          icon="XIcon"
          class="mr-2 ml-auto mt-1"
          style="cursor: pointer; position: absolute; right: 0px"
          size="30"
          @click="closeIfoSlot()"
        />
        <div>
          <!-- Info -->
          <info-slot :data="selectedObject" v-if="selectedObject" />
          <b-spinner
            v-else
            label="Spinning"
            variant="primary"
            class="spinner"
          ></b-spinner>
        </div>
      </b-card>
    </b-card-group>

    <alarm-action-modal />
    <image-modal/>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BPagination,
  BBadge,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import useList from "./useList";
import Filters from "./Filters.vue";
import { ref } from "@vue/composition-api";
import InfoSlot from "../components/GridView/layouts/slots/InfoSlot";
import { SOURCES } from "@/config/layoutConfig";
import Pagination from "@/components/Pagination.vue";
import ToastificationLoadingContent from "@core/components/toastification/ToastificationLoadingContent.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import FilterModal from "@/components/Filters/FilterModal.vue";
import axios from "@axios";
import ButtonFilters from "@/components/Filters/ButtonFilters.vue";
import ImageModal from '../components/GridView/widgets/ImageModal.vue';

import AlarmActionModal from "../components/GridView/widgets/AlarmActionModal.vue";

export default {
  components: {
    Breadcrumb,
    Filters,
    InfoSlot,
    Pagination,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BBadge,
    ToastificationLoadingContent,
    FilterModal,
    ButtonFilters,
    BSpinner,
    AlarmActionModal,
    ImageModal,
  },
  props: {
    filters: {
      type: Object | Array,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const useListRef = useList();

    //start pagination
    const update = (data) => {
      useListRef.currentPage.value = data;
    };
    //end pagination

    if (props.showFilters) useListRef.initFilters()

    const handleCameraFilterUpdate = (newFilter) => {
      useListRef.cameraFilter.value = newFilter;
    };

    const hiddenTableGrid = ref(false);

    const closeIfoSlot = () => {
      useListRef.selectedObject.value = null
      useListRef.loadingSelected.value = false;
      hiddenTableGrid.value = false;
    };

    const onRowSelected = async (item) => {
      useListRef.selectedObject.value = null;
      if (!item.length) {
        useListRef.loadingSelected.value = false;
        return;
      }

      useListRef.loadingSelected.value = true;

      if (!props.showFilters) {
        hiddenTableGrid.value = true;
      }

      const { data } = await axios.get(item[0].details_link);
      useListRef.selectedObject.value = {
        data: {
          ...data.data,
          in_app_source: SOURCES.ALARM,
        },

      };
      useListRef.loadingSelected.value = false;


    };

    const exportExcel = () => {
      useListRef.action.value = "export";
      useListRef.disableExport.value = true;

      useListRef.fetchList();
    };

    useListRef.applyChartFilters(props.filters);

    const itemFilter = ref();
    const addItemFilter = (data) => {
      itemFilter.value = data;
    };

    const addItemFilterSearch = (data) => {
      data.forEach((element) => {
        if (nameNav.value === element.text) {
          useListRef.searchQuery.value = element.searchQuery;
        }
      });
    };

    const nameNav = ref("breadcrumbs.alarms.all");

    return {
      //Breadcrumb
      nameNav,
      itemFilter,
      addItemFilter,
      addItemFilterSearch,
      handleCameraFilterUpdate,
      onRowSelected,
      exportExcel,
      update,
      hiddenTableGrid,
      closeIfoSlot,

      ...useListRef
    };
  },
};
</script>

<style lang="scss" scoped>
.sticky-card {
  position: sticky;
  top: 6rem;
  height: 80vh;
  overflow: auto;
}

.spinner {
  margin-top: calc(50% - 100px);
  margin-left: 50%;
}

.showScroll {
  position: sticky;
  top: 6rem;
  height: auto;
  overflow: hidden;
}
</style>
