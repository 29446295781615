import CameraManager from "./CameraManager";
import Vxg from "./Vxg";
import GenericDevice from "./GenericDevice";

export default class {

  static create(driver) {
    switch (driver) {
      case 'camera_manager':
        return new CameraManager;

      case 'vxg':
        return new Vxg;

      case 'generic_device':
        return new GenericDevice;

      default:
        throw new Error('Invalid driver.');
    }

  }

}
