import axios from '@axios';
import moment from "moment";


export const cameraOptions = (camera_id, camera_type) => {

  return new Promise((resolve, reject) => {
    axios
      .get("/v1/services/device/get-options", {
        params: {
          camera_id,
          camera_type,
          withOldestRecording: true,
          withLatestRecording: true,
          withTzData: true
        },
      })
      .then((resp) => resolve(resp.data.data))
      .catch((error) => reject(error));
  });

};
