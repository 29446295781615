<template>
  <div>
    <p class="lead text-center font-medium-3">
      {{ $t("grid.slots.info.analytic_record.title") }}
    </p>
    <tr style="display: block">
        <carousel
          :v-bind="arMatches"
          :per-page="1"
          :adjustableHeight="true"
          paginationPosition="bottom-overlay"
          :paginationEnabled="false"
          :centerMode="true"
          paginationActiveColor="#1C3D5E"
          :navigationEnabled="true"
          @page-change="pageChange"
          class="carousel-infoslot"
        >
          <slide v-for="(value, key) in arMatches" :key="`slide-${key}`">
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
              "
              v-if="value == ''"
            >
              <b-spinner variant="primary" style="margin-top: 20px"></b-spinner>
            </div>
            <ar-data
              v-else
              :key="`ar-data-${key}-${value.created}`"
              :dataMatch="value"
              :index="key"
            />
          </slide>
        </carousel>
    </tr>
  </div>
</template>
<script>
import store from "@/store";
import moment from "moment-timezone";
import { EventBus } from "@/libs/event-bus";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ARData from "./ARData.vue";
import axios from "@axios";
import { BButton } from "bootstrap-vue";
import { Carousel, Slide } from "vue-carousel";

export default {
  components: {
    "ar-data": ARData,
    BButton,
    Carousel,
    Slide,
  },
  props: {
    data: Object,
    totalMatches: Number,
  },
  data() {
    return {
      arMatches: [],
    };
  },
  methods: {
    showMessage(title, msg, variant = "success") {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title,
          variant,
          icon: "BellIcon",
          text: msg,
          timeout: 5000,
        },
      });
    },
    pageChange(pageNumber) {
      console.log({ pageNumber });
      axios
        .post(
          `${process.env.VUE_APP_BASE_URL}/v1/analytic/analyticRecordByIndex`,
          {
            alarmId: this.$props.data.id_alarm,
            index: pageNumber,
          }
        )
        .then((res) => {
          console.log(res);
          const arMatch = res.data.data;
          //Validar el 0
          this.$set(this.arMatches, pageNumber, arMatch);
          console.log(this.arMatches);
        });
    },
  },

  async mounted() {
    const totalMatches = this.$props.totalMatches;
    console.log(totalMatches);
    this.$set(this.arMatches, 0, this.$props.data);
    for (let index = 1; index <= totalMatches - 1; index++) {
      this.$set(this.arMatches, index, "");
      //this.arMatches[index] = "";
    }
    console.log(this.arMatches);
  },
};
</script>
<style>
.carousel-infoslot .VueCarousel-navigation-prev {
  top: 30%;
  left: 15px;
  font-size: x-large;
}

.carousel-infoslot .VueCarousel-navigation-next {
  top: 30%;
  right: 15px;
  font-size: x-large;
}
</style>
