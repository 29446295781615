<template>
  <div>
    <div v-if="!loading">
      <template v-if="actionPlanHistory">
        <!-- <p class="lead text-center font-medium-3">
          {{ $t("action_plan.title") }}
        </p> -->
        <div class="w-100">
          <action-plan-viewer
            v-model="actionPlanHistory"
            :action-plan-id="actionPlanSelectedId"
            :parent-container="$el"
            :config="config"
          />
        </div>
      </template>
    </div>
    <div class="pb-2 text-center" v-else>
      <b-spinner v-if="!loading" label="Spinning" variant="primary"></b-spinner>
    </div>
  </div>
</template>

<script>
import axios from "@axios";
import { BSpinner } from "bootstrap-vue";
import ActionPlanViewer from "@/components/ActionPlanViewer";

export default {
  props: {
    data: Object,
  },
  components: {
    BSpinner,
    ActionPlanViewer,
  },
  data() {
    return {
      loading: true,
      actionPlanSelectedId: null,
      actionPlanHistory: null,
      config: {
        userId: null,
        alarmId: null,
        alarmId: null,
      },
    };
  },
  mounted() {
    this.fetchActionPlans().then((resp) => {
      const { data: data } = resp.data;
      if (data.type === "action_plan_log") {
        const isEmpty = Array.isArray(data.data) && data.data.length === 0;
        this.actionPlanSelectedId = data.id_action_plan;
        this.actionPlanHistory = !isEmpty ? data.data : {};
      }
      this.loading = false;
    });
  },
  methods: {
    fetchActionPlans() {
      return axios.get("/v1/action_plan/find-action-plan", {
        params: {
          id_alarm: this.data.id,
          alarm_type: this.data.type,
          id_tenant: this.data.tenant.id,
          check_for_logs: true,
        },
      });
    },
  },
};
</script>

<style></style>
