<template>
  <div>
    <div v-if="unit">
      <p class="lead text-center">{{ unit.description }}</p>
      <table class="table">
        <tr>
          <th>{{ $t("grid.slots.info.company") }}</th>
          <td>{{ unit.company }}</td>
        </tr>
        <tr>
          <th>{{ $t("grid.slots.info.latitude") }}</th>
          <td>{{ unit.coordinates.lat }}</td>
        </tr>
        <tr>
          <th>{{ $t("grid.slots.info.longitude") }}</th>
          <td>{{ unit.coordinates.lng }}</td>
        </tr>
        <tr>
          <th>{{ $t("grid.slots.info.address") }} 1</th>
          <td>{{ unit.address_1 }}</td>
        </tr>
        <tr>
          <th>{{ $t("grid.slots.info.address") }} 2</th>
          <td>{{ unit.address_2 }}</td>
        </tr>
        <tr>
          <th>{{ $t("grid.slots.info.exterior_number") }}</th>
          <td>{{ unit.exterior_number }}</td>
        </tr>
        <tr>
          <th>{{ $t("grid.slots.info.suburb") }}</th>
          <td>{{ unit.suburb }}</td>
        </tr>
        <tr>
          <th>{{ $t("grid.slots.info.municipality") }}</th>
          <td>{{unit.municipality ? unit.municipality.name : " - " }}</td>
        </tr>
        <tr>
          <th>{{ $t("grid.slots.info.postal_code") }}</th>
          <td>{{ unit.postal_code }}</td>
        </tr>
        <tr>
          <th>{{ $t("grid.slots.info.country") }}</th>
          <td>{{ unit.country ? unit.country.name : " - " }}</td>
        </tr>
        <tr>
          <th>{{ $t("grid.slots.info.region") }}</th>
          <td>{{ unit.country ? unit.region.name : " - " }}</td>
        </tr>
      </table>
    </div>
    <div class="pb-2 text-center" v-else>
      <b-spinner label="Spinning" variant="primary"></b-spinner>
    </div>
  </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";
import axios from "@axios";

export default {
  props: {
    data: Object,
  },
  components: {
    BSpinner,
  },
  data() {
    return {
      unit: null,
    };
  },
  mounted() {
    axios.get(this.data.url).then(({ data }) => {
      this.unit = data.data;
    });
  },
};
</script>

<style></style>
