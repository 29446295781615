<template>
  <div>
    <div v-if="swift_sensor">
      <p class="lead text-center">{{ $t("grid.slots.info.sensors.title") }}</p>
      <table class="table">
        <tr>
          <th>{{ $t("grid.slots.info.sensors.type") }}</th>
          <td>{{ swift_sensor.swift_type_sensor.name }}</td>
        </tr>
        <tr>
          <th>{{ $t("grid.slots.info.sensors.name") }}</th>
          <td>{{ swift_sensor.name }}</td>
        </tr>
        <tr>
          <th>{{ $t("grid.slots.info.sensors.last_measurement") }}</th>
          <td>
            {{
              `${parseFloat(
                data.measurement ? data.measurement : swift_sensor.last_measurement
              ).toFixed(2)} ${swift_sensor.swift_unit_measure.abbrev}
              `
            }}
          </td>
        </tr>
        <tr style="color: #ff5f4a">
          <th>{{ $t("grid.slots.info.sensors.high_critical") }}</th>
          <td>
            {{
              `${
                swift_sensor.threshold.maxCritical != null
                  ? swift_sensor.threshold.maxCritical
                  : "N/A"
              } ${swift_sensor.swift_unit_measure.abbrev}`
            }}
          </td>
        </tr>
        <tr style="color: #fb3">
          <th>{{ $t("grid.slots.info.sensors.high_warning") }}</th>
          <td>
            {{
              `${
                swift_sensor.threshold.maxWarning != null
                  ? swift_sensor.threshold.maxWarning
                  : "N/A"
              } ${swift_sensor.swift_unit_measure.abbrev}`
            }}
          </td>
        </tr>
        <tr style="color: #fb3">
          <th>{{ $t("grid.slots.info.sensors.low_warning") }}</th>
          <td>
            {{
              `${
                swift_sensor.threshold.minWarning != null
                  ? swift_sensor.threshold.minWarning
                  : "N/A"
              } ${swift_sensor.swift_unit_measure.abbrev}`
            }}
          </td>
        </tr>
        <tr style="color: #ff5f4a">
          <th>{{ $t("grid.slots.info.sensors.low_critical") }}</th>
          <td>
            {{
              `${
                swift_sensor.threshold.minCritical != null
                  ? swift_sensor.threshold.minCritical
                  : "N/A"
              } ${swift_sensor.swift_unit_measure.abbrev}`
            }}
          </td>
        </tr>
      </table>
    </div>
    <div class="pb-2 text-center" v-else>
      <b-spinner label="Spinning" variant="primary"></b-spinner>
    </div>
  </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";
import store from "@/store";

export default {
  props: {
    data: Object,
  },
  components: {
    BSpinner,
  },
  data() {
    return {
      swift_sensor: null,
    };
  },
  mounted() {
    let id_swift_sensor = null;

    if (this.data.id_swift_sensor) {
      id_swift_sensor = this.data.id_swift_sensor;
    } else if (this.data.id_sensor) {
      id_swift_sensor = this.data.id_sensor;
    }

    store
      .dispatch("swift_sensor/get", id_swift_sensor)
      .then((response) => {
        this.swift_sensor = response.data;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error obteniendo la información",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  },
};
</script>

<style></style>
