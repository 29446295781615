<template>
    <b-sidebar
        id="sidebar"
        :visible="isSidebarActive"
        bg-variant="white"
        sidebar-class="sidebar-lg full-width-custom"
        shadow
        backdrop
        no-header
        no-close-on-backdrop
        right
        @shown="canvasCreateImage"
        @hidden="resetData"
        @change="(val) => $emit('update:is-sidebar-active', val)"
    >
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0 text-capitalize">
                    {{ $t("tags.title") }}
                </h5>
                <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="hide"
                />
            </div>

            <!-- BODY -->
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <!-- Form -->
                <b-form
                    class="p-2"
                    @submit.prevent="handleSubmit(onSubmit)"
                    @reset.prevent="resetForm"
                >
                    <!-- Category -->
                    <validation-provider
                        #default="validationContext"
                        name="tag"
                        rules="required"
                    >
                        <b-form-group
                            class="text-capitalize"
                            :label="$t('tags.category')"
                            label-for="tag"
                            :state="getValidationState(validationContext)"
                        >
                            <v-select
                                label="tag"
                                v-model="itemData.id_request_tag"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="requestTags"
                                :clearable="false"
                                :reduce="(val) => val.id"
                                :placeholder="$t('tags.placeholderCategory')"
                                input-id="id_request_tag"
                            />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Description -->
                    <validation-provider
                        #default="validationContext"
                        name="description"
                        rules="required"
                    >
                        <b-form-group
                            :label="$t('tags.description')"
                            label-for="description"
                        >
                            <b-form-input
                                id="description"
                                v-model="itemData.description"
                                :placeholder="$t('tags.placeholderDescription')"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Points -->
                   <!--  <validation-provider
                        #default="validationContext"
                        name="data"
                        rules="required"
                    > -->
                        <b-form-group
                            :label="$t('tags.image')"
                            label-for="data"
                        >
                            <div class="d-flex justify-content-center">
                                <canvas ref="canvas"></canvas>
                            </div>
                            <div class="text-center">
                                <b-button
                                    type="button"
                                    class="mt-2 text-capitalize"
                                    variant="outline-secondary"
                                    @click="cleanPolygon()"
                                >
                                    Limpiar
                                </b-button>
                            </div>
                            <!-- <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback> -->
                        </b-form-group>
                    <!-- </validation-provider> -->

                    <!-- Date tag -->
                    <validation-provider
                        #default="validationContext"
                        name="date"
                        rules="required"
                    >
                        <b-form-group
                            :label="$t('tags.date')"
                            label-for="date"
                        >
                            <input
                                type="datetime-local"
                                class="form-control tag_date"
                                v-model="itemData.tag_created"
                                :state="getValidationState(validationContext)"
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                     <!-- Form Actions -->
                     <div class="d-flex mt-2">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-2 text-capitalize"
                            type="submit"
                        >
                            {{ $t('actions.add') }}
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="button"
                            variant="outline-secondary"
                            @click="hide"
                            class="text-capitalize"
                            >
                                {{$t('actions.cancel')}}
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>
import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { ref, onMounted } from "@vue/composition-api";
import { required, alphaNum, email, positive, required_if } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import axios from '@/libs/axios';
import flatpickr from "flatpickr";

export default {
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        vSelect,
        BFormCheckbox,
        ValidationProvider,
        ValidationObserver
    },
    directives: {
        Ripple,
    },
    model: {
        prop: "isSidebarActive",
        event: "update:is-sidebar-active"
    },
    props: {
        idCamera: {
            type: Number,
            required: true
        },
        isSidebarActive: {
            type: Boolean,
            required: true,
        },
        imageTagUrl: {
            type: String,
            required: false,
        },
        valueDate: {
            type: String,
            default: '',
            required: false
        }
    },
    data() {
        return {
            required,
            alphaNum,
            email,
            positive,
        }
    },
    setup(props, { emit }) {
        //Refs
        const canvas = ref(null);

        //Mounted
        onMounted(async () => {
            const config = {
                enableTime: true,
                time_24hr: true,
                dateFormat: "Y-m-d H:i",
            }

            getTagsTypes();
            flatpickr('input[type=datetime-local]', config);
        });

        //Data
        const blankData = {
            description: null,
            id_camera: null,
            id_request_tag: null,
            tag_created: null,
            data: []
        }

        const context  = ref(null);

        const itemData = ref(JSON.parse(JSON.stringify(blankData)));

        const imageTag = ref(null);

        const requestTags = ref([]);

        const { t } = useI18nUtils();

        const toast = useToast();

        const { refFormObserver, getValidationState } = formValidation(resetData);

        //Methods
        const canvasCreateImage = () => {
            itemData.value.id_camera   = props.idCamera;
            itemData.value.tag_created = props.valueDate;
            context.value  = canvas.value.getContext('2d');
            imageTag.value = new Image();

            imageTag.value.onload = () => imageLoad();

            imageTag.value.src = props.imageTagUrl;

            canvas.value.onmouseup = (e) => canvasMouseUp(e);
        }

        const canvasMouseUp = (event) => {
            let rect   = canvas.value.getBoundingClientRect();
            let pointX = (event.clientX - rect.left) / canvas.value.width * 100;
            let pointY = (event.clientY - rect.top) / canvas.value.height * 100;
            let { offsetX, offsetY } = event;

            itemData.value.data.push({
                x: offsetX,
                pointX: pointX,
                y: offsetY,
                pointY: pointY
            });

            redraw();
        }

        const cleanPolygon = () => {
            context.value.clearRect(0, 0, canvas.value.width, canvas.value.height);
            context.value.drawImage(imageTag.value, 0, 0, canvas.value.width, canvas.value.height);
            itemData.value.data = [];
        }

        const contentToast = (message,type,icon) => toast({
            component: ToastificationContent,
            props: {
                title: message,
                icon: icon,
                variant: type
            }
        })

        const drawPoints = () => {
            context.value.lineJoin    = 'round';
            context.value.lineWidth   = 5;
            context.value.strokeStyle = '#df4b26';

            for(var i = 0; i < itemData.value.data.length; i++) {
                let { x,y } = itemData.value.data[i];

                context.value.beginPath();
                context.value.arc(x, y, 3, 0, 2 * Math.PI, false);
                context.value.fillStyle = '#ffffff';
                context.value.fill();
                context.value.lineWidth = 5;
                context.value.stroke();
            }
        }

        const drawPolygon = () => {
            let [firstPoints] = itemData.value.data;

            context.value.fillStyle   = 'rgba(100,100,100,0.2)';
            context.value.strokeStyle = '#df4b26';
            context.value.lineWidth   = 1;

            context.value.beginPath();
            context.value.moveTo(firstPoints.x, firstPoints.y);

            for(let i = 1; i < itemData.value.data.length; i++) {
                let { x,y } = itemData.value.data[i];

                context.value.lineTo(x,y);
            }

            context.value.closePath();
            context.value.fill();
            context.value.stroke();
        }

        const getTagsTypes = async () => {
            try {
                let { data } = await axios.get('/v1/request_tag');

                requestTags.value = data.data;
            } catch (error) {
                requestTags.value = [];
            }
        }

        const imageLoad = (e) => {
            let ratio  = imageTag.value.width / imageTag.value.height;
            let width  = imageTag.value.width;
            let height = parseInt(width / ratio, 10);

            canvas.value.setAttribute('width', width);
            canvas.value.setAttribute('height', height);
            context.value.fillRect(0, 0, width, height);
            context.value.drawImage(imageTag.value, 0, 0, width, height);
        }

        const redraw = () => {
            canvas.value.width = canvas.value.width;
            context.value.drawImage(imageTag.value, 0, 0);

            drawPolygon();
            drawPoints();
        }

        const resetData = () => {
            cleanPolygon();

            canvas.value   = null;
            context.value  = null;
            imageTag.value = null;
            itemData.value = JSON.parse(JSON.stringify(blankData));
        }

        const tranformData = () => {
            itemData.value.data = itemData.value.data.map(points => {
                return {
                    x: points.pointX,
                    y: points.pointY
                }
            });
        }

        const onSubmit = async () => {
            tranformData();

            itemData.value.data = JSON.stringify(itemData.value.data);

            try {
                let { data } = await axios.post('/v1/video_tag', itemData.value);

                contentToast(data.message,'CheckIcon','success');
                emit('update:is-sidebar-active', false);
            } catch (error) {
                contentToast('Error','AlertTriangleIcon','danger');
            }
        }

        return {
            canvas,
            getValidationState,
            itemData,
            refFormObserver,
            requestTags,

            canvasCreateImage,
            cleanPolygon,
            resetData,
            onSubmit
        }
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.full-width-custom {
  min-width: 45vw;
}

.b-sidebar-body::-webkit-scrollbar {
  display: none;
}
</style>
