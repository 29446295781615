import moment from "moment";
import ConfigBase from "./ConfigBase";

export default class extends ConfigBase {

  setConfig() {
    return {
      dateFormat: 'YYYY-MM-DDTHH:mm:ss',
      // minTimelineDate: 15, // days,
      lastMinDateRequested: 1 * 60 * 60, // seconds
      minTimeToLoadNextRequest: { // minutes
        currentZoom0: 60 * 8,
        currentZoom1: 60,
        currentZoom4: 45,
      },
      fetchNewestRecordingsTime: 30 * 1000,
      fetchDataOnDemandBackRequestTime: 55 * 60, // 55 min to seconds,
      fetchDataOnDemandForwardRequestTime: 55 * 60, // 55 min to seconds,
      goToDateHourOfDay: 0, // Hour of day 0 to 23
      goToDateMinMax: (date) => {
        const now = moment(date);
        const min = now.clone().startOf('day');
        const max = min.clone().add(1, 'hours');

        return { min, max };
      }
    }
  }

}
