<template>
  <div v-if="eventData">
    <p class="lead text-center font-medium-3">
      {{ $t(`grid.slots.info.detection_events.${eventData.event_type}.title`) }}
    </p>
    <div style="height: 180px;">
      <cam-slot v-if="camera_data" :data="camera_data" :type="cam_type" />
    </div>
    <div class="w-75 mx-auto">
      <div class="row my-2" v-if="data">
        <div class="col-12 text-center" v-if="images && images.length">
          <p class="my-0 mt-1">
            {{ $t(`grid.slots.info.detection_events.labels.overall_picture`) }}
          </p>
          <img
            class="img-thumbnail"
            :src="images[0]"
            alt=""
            @click="openImageModal(images[0])"
            v-b-modal.image_modal
          />
        </div>
        <div class="col-12 text-center" v-else>
          <p class="mt-1 lead">No image</p>
        </div>
        <div v-if="media">
          <hr class="mb-2" />
          <div v-for="(mediaItem, index) in media" :key="index">
            <div class="col-12 mb-2" v-if="mediaItem.mime_type === 'video/mp4'">
              <video class="w-100" :src="mediaItem.url" controls></video>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import CamSlot from "../CamSlot.vue";

export default {
  components: {
    CamSlot,
  },
  props: {
    detectionEventData: Object,
    camera_data: Object,
  },
  data() {
    return {
      eventData: null,
      data: null,
      images: "",
      media: null,
      cam_type: "cam_pa",
    };
  },
  async mounted() {
    this.eventData = this.detectionEventData;
    this.data = this.detectionEventData.data;
    this.images = this.detectionEventData.data.images;
    this.media = this.detectionEventData.media;
  },
  methods: {
    formatNum(num, decimals = 0) {
      return parseFloat(num).toFixed(decimals);
    },
    async openImageModal(imageUrl) {
      await store.dispatch("image_modal/imageUrl", imageUrl);
    },
  },
};
</script>

<style></style>
