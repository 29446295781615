<template>
  <div>
    <p class="lead text-center font-medium-3">Smart Detection</p>
    <div class="w-100">
      <img 
        class="img-thumbnail" 
        :src="imgUrl" alt="" 
        @click="openImageModal(imgUrl)" 
        v-b-modal.image_modal
      />
    </div>
  </div>
</template>

<script>
import store from "@/store";

export default {
  props: {
    sdId: String | Number,
    host: String,
  },
  data() {
    return {
      imgUrl: null,
    };
  },
  mounted() {
    this.imgUrl = `${this.host}/v1/events/image/${this.sdId}`;
  },
  methods: {
    async openImageModal(imageUrl){
      await store.dispatch("image_modal/imageUrl", imageUrl );
    }
  }
};
</script>

<style></style>
