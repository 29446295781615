<template>
  <div>
    <div v-if="!loadingLogs && logs && logs.length">
      <p class="lead text-center font-medium-3">{{ $t('grid.slots.info.workflow_logs.title') }}</p>
      <div class="w-100">
        <ul class="list-unstyled">
          <!-- Workflows -->
          <li class="mb-2" v-for="(log, index) in logs" :key="index">
            <p class="lead">{{ log.workflow }}</p>

            <!-- Stacks -->
            <ol v-if="log.stack.length">
              <li v-for="(cmd, stack_index) in log.stack" :key="stack_index">
                <!-- Command -->
                <template v-if="cmd.type === 'cmd'">
                  <feather-icon icon="PlayCircleIcon" size="18" class="mr-25" />
                  {{ $t(`commands.list.${cmd.cmd_name}.name`) }}
                  <div class="cmd-data pl-2 small" v-html="parseCmdData(cmd)"></div>
                </template>

                <!-- Delay -->
                <template v-else>
                  <feather-icon icon="PauseCircleIcon" size="18" class="mr-25" />
                  {{ $t(`commands.list.${cmd.cmd_name}.name`) }} ({{ cmd.data }} {{ $t('commands.units.seconds') }})
                </template>
              </li>
            </ol>
          </li>
        </ul>
      </div>
    </div>
    <div class="pb-2 text-center" v-else>
      <b-spinner v-if="loadingLogs" label="Spinning" variant="primary"></b-spinner>
    </div>
  </div>
</template>

<script>
import axios from "@axios";
import { BSpinner } from "bootstrap-vue";
import commandStackHelperMixin from "@/mixins/commandStackHelperMixin";

export default {
  mixins: [commandStackHelperMixin],
  components: {
    BSpinner,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      logs: null,
      loadingLogs: true,
    };
  },
  mounted() {
    this.getCommandLogByAlarmId(this.data.id)
      .then(({ data }) => {
        this.logs = data.data;
        this.loadingLogs = false;
      })
      .finally(() => (this.loadingLogs = false));
  },
  methods: {
    getCommandLogByAlarmId(id_alarm) {
      return axios.get("/v1/command/command-log-by-alarm-id", { params: { id_alarm } });
    },
  },
};
</script>

<style></style>
