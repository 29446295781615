<template>
  <div>
    <div v-if="!loading">
      <pre>{{ JSON.stringify(deviceData, null, "\t") }}</pre>
    </div>
    <div class="pb-2 text-center" v-else>
      <b-spinner label="Spinning" variant="primary"></b-spinner>
    </div>
  </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";
import axios from "@axios";

export default {
  props: {
    data: Object,
  },
  components: {
    BSpinner,
  },
  data() {
    return {
      loading: true,
      deviceData: {},
    };
  },
  mounted() {
    axios.get(this.data.url).then(({ data }) => {
      this.deviceData = data.data;
      this.loading = false;
    });
  },
};
</script>
