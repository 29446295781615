<template>
  <div class="w-80 h-100">
    <div class="w-75 mx-auto">
      <div class="row my-2">
        <div class="col-12 mb-2">
          <vueper-slides fixed-height="240px">
            <vueper-slide
              v-for="(value, key) in dataMatch.media"
              :key="`slide-${key}`"
              @click.native="openImageModal(value.url)" 
              v-b-modal.image_modal 
            >
              <template #content>
                <div
                  style="
                    position: relative;
                    display: inline-block;
                    overflow: hidden;
                  "
                >
                  <div
                    style="
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      z-index: 3;
                    "
                  >
                    <div
                      style="
                        color: white;
                        width: 100%;
                        background-color: rgba(0, 0, 0, 0) !important;
                        text-shadow: 1px 2px #000;
                        padding: 0.5rem;
                        top: 0;
                        background: linear-gradient(
                          0deg,
                          transparent 0%,
                          rgba(0, 0, 0, 0.5) 95%
                        );
                      "
                    >
                      <span style="display: block">{{
                        dataMatch.camera_name
                      }}</span>
                      <span
                        style="
                          position: absolute;
                          top: 0;
                          right: 0;
                          padding: 0.5rem;
                        "
                        >{{ formatDateAssigned(dataMatch.created_at) }}</span
                      >
                    </div>

                    <div
                      style="
                        position: absolute;
                        color: white;
                        width: 100%;
                        background-color: rgba(0, 0, 0, 0) !important;
                        text-shadow: 1px 2px #000;
                        padding: 0.5rem;
                        bottom: 0;
                        background: linear-gradient(
                          transparent,
                          rgba(0, 0, 0, 0.5)
                        );
                      "
                    >
                      <span style="bottom: 0">{{ dataMatch.priority }}</span>
                    </div>
                  </div>
                  <img 
                    class="img-thumbnail" 
                    :src="value.url" alt="" 
                  />
                </div>
              </template>
            </vueper-slide>
          </vueper-slides>
          <!-- <carousel
            :v-bind="dataMatch"
            :per-page="1"
            :adjustableHeight="true"
            paginationPosition="bottom-overlay"
            :paginationEnabled="false"
            :centerMode="true"
            paginationActiveColor="#1C3D5E"
            :navigationEnabled="true"
            class="carousel-arimage"
            id=""
          >
            <slide
              v-for="(value, key) in dataMatch.media"
              :key="`ar-data-slide-${key}`"
              class="carousel-arimage"
            >
              <div
                style="
                  position: relative;
                  display: inline-block;
                  overflow: hidden;
                "
              >
                <div
                  style="
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 3;
                  "
                >
                  <div
                    style="
                      color: white;
                      width: 100%;
                      background-color: rgba(0, 0, 0, 0) !important;
                      text-shadow: 1px 2px #000;
                      padding: 0.5rem;
                      top: 0;
                      background: linear-gradient(
                        0deg,
                        transparent 0%,
                        rgba(0, 0, 0, 0.5) 95%
                      );
                    "
                  >
                    <span style="display: block">{{
                      dataMatch.alarm_category
                    }}</span>
                    <span style="display: block">{{
                      dataMatch.type_alarm
                    }}</span>
                    <span
                      style="
                        position: absolute;
                        top: 0;
                        right: 0;
                        padding: 0.5rem;
                      "
                      >{{ formatDateAssigned(dataMatch.created_at) }}</span
                    >
                  </div>

                  <div
                    style="
                      position: absolute;
                      color: white;
                      width: 100%;
                      background-color: rgba(0, 0, 0, 0) !important;
                      text-shadow: 1px 2px #000;
                      padding: 0.5rem;
                      bottom: 0;
                      background: linear-gradient(
                        transparent,
                        rgba(0, 0, 0, 0.5)
                      );
                    "
                  >
                    <span style="bottom: 0">{{ dataMatch.priority }}</span>
                  </div>
                </div>
                <img class="img-thumbnail" :src="value.url" alt="" />
              </div>
            </slide>
          </carousel> -->
        </div>
      </div>
    </div>
    <table class="table table-sm" v-if="dataMatch">
      <tr>
        <th>{{ $t("grid.slots.info.analytic_record.alarm_category") }}</th>
        <td>{{ dataMatch.alarm_category }}</td>
      </tr>
      <tr>
        <th>{{ $t("alarm.alarm_type") }}</th>
        <td>{{ dataMatch.type_alarm }}</td>
      </tr>
      <tr>
        <th>{{ $t("alarm.created") }}</th>
        <td>{{ dataMatch.alarm_created_at }}</td>
      </tr>
      <tr>
        <th>{{ $t("grid.slots.info.analytic_record.matched_rules") }}</th>
        <td>{{ dataMatch.matched_rules }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from "@axios";
import { BButton } from "bootstrap-vue";
import moment from "moment-timezone";
import { Carousel, Slide } from "vue-carousel";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import store from "@/store";

export default {
  components: {
    BButton,
    Carousel,
    Slide,
    VueperSlides,
    VueperSlide,
  },
  props: {
    dataMatch: Object,
  },
  data() {
    return {};
  },
  async mounted() {
    console.log(this.$props.dataMatch);
  },
  methods: {
    formatPercentages(num) {
      return `${(parseFloat(num) * 100).toFixed(2)} %`;
    },
    formatNum(num, decimals = 0) {
      return parseFloat(num).toFixed(decimals);
    },
    formatDateAssigned(value) {
      const formattedDate = moment(value).format("DD/MM/YYYY HH:mm:ss");
      return formattedDate;
    },
    async openImageModal(imageUrl){
      await store.dispatch("image_modal/imageUrl", imageUrl );
    }
  },
};
</script>

<style>
.carousel-arimage {
  width: 100%;
  height: 100%;
}
</style>
