import { ref, computed, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store';
import { useUtils as useI18nUtils } from "@core/libs/i18n"
import dateParser from "@/libs/date-parser";
import useFilterCounter from '@/components/Filters/useFiltersCount'
import { debounce } from 'lodash';

export default function useList() {
  const refListTable = ref(null)

  const toast = useToast()
  const { t } = useI18nUtils();

  const tableColumns = [
    { label: 'alarm.status', key: 'alarm_status', sortable: true },
    { label: 'camera.title', key: 'detection_event_data.camera_data.camera.name', sortable: false },
    { label: 'alarm.alarm_type', key: 'alarm_type', sortable: true },
    { label: 'tenants.title', key: 'tenant.name', sortable: false },
    {
      label: 'date.created_at',
      key: 'created',
      sortable: true,
      formatter: (value, key, item) => {
        return dateParser.parseDate(value)
      },
      thStyle: 'min-width: 215px'
    },
    { label: 'alarm.title', key: 'id', sortable: true },
    // { label: 'alarm.folioCAD', key: 'folio_cad', sortable: true },
    // { label: 'alarm.statusCAD', key: 'status_cad', sortable: true },
    { label: 'router.latitude', key: 'unit.lat', sortable: true },
    { label: 'router.longitude', key: 'unit.lng', sortable: true },
    { label: 'unit.address_1', key: 'unit.address_1', sortable: true },
    { label: 'unit.address_2', key: 'unit.address_2', sortable: true },
    { label: 'unit.exterior_number', key: 'unit.exterior_number', sortable: true },
    { label: 'unit.suburb', key: 'unit.suburb', sortable: true },
    { label: 'unit.postal_code', key: 'unit.postal_code', sortable: true },
    { label: 'country.title', key: 'unit.country.name', sortable: true },
    { label: 'region.title', key: 'unit.region.name', sortable: true },
    { label: 'municipality.title', key: 'unit.municipality.name', sortable: true },
    // { label: 'alarm.reaction_patrol', key: 'alarm', sortable: true },
  ]

  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })

  const initFilters = () => {
    store.dispatch("unit/getAll");
    store.dispatch('tenant/getAll')
    store.dispatch('type_alarm/getAll')
  }

  const unitOptions = computed(() => store.getters['unit/getSelectedItems'])
  const unitFilter = ref(null);

  const tenantOptions = computed(() => store.getters['tenant/getSelectedItems'])
  const tenantFilter = ref(null)

  const alarmTypeOptions = computed(() => store.getters['type_alarm/getSelectedItems'])
  const alarmTypeFilter = ref(null)

  const statusFilter = ref([])
  const dateFilter = ref(['created'])

  const attendedFilter = ref(null)
  const attendingFilter = ref(null)
  const canceledFilter = ref(null)
  const waitingFilter = ref(null)

  const action = ref('list')
  const disableExport = ref(false)

  const startDatetimeFilter = ref(null)
  const endDatetimeFilter = ref(null)
  const loadingToast = ref(null);

  const createdFilter = ref(null);

  const cameraFilter = ref(null)

  const selectedObject = ref(null);
  const loadingSelected = ref(false);

  const trans = {
    "messages.export.download": t("messages.export.download"),
    "messages.export.Generating_file": t("messages.export.Generating_file")
  };

  const fetchList = (ctx, callback) => {
    if (action.value == 'export') {
      loadingToast.value.show(
        trans["messages.export.download"],
        trans["messages.export.Generating_file"],
        "secondary"
      );
    }
    store.dispatch('alarm/getAll', {
      q: searchQuery.value,
      max: perPage.value,
      'page[n]': currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      waiting: waitingFilter.value,
      attending: attendingFilter.value,
      attended: attendedFilter.value,
      canceled: canceledFilter.value,
      id_tenant: tenantFilter.value,
      type: alarmTypeFilter.value,
      dt_from: startDatetimeFilter.value,
      dt_to: endDatetimeFilter.value,
      dateFilter: dateFilter.value,
      created: createdFilter.value,
      action: action.value,
      units: unitFilter.value,
      multiple_id_cameras_proxy: cameraFilter.value,
      with: [
        'router.unit.country',
        'router.unit.region',
        'router.unit.municipality',
        'router.tenant',
        'detectionEvent',
        'alarm_log.user',
        'alarmType',
      ]
    })
      .then(response => {
        if (action.value == 'export') {
          action.value = 'list'
          disableExport.value = false
          loadingToast.value.close();
        } else {
          response.data.forEach(element => {
            let bandage_style = element.attending ? 'danger' :
              element.attended ? 'success' :
                element.waiting ? 'primary' :
                  element.canceled ? 'dark' : 'danger'
            element.bandage_style = bandage_style
          });
          callback(response.data)
          totalList.value = response.meta.total
        }
      })
      .catch((e) => {
        action.value = 'list'
        disableExport.value = false
        loadingToast.value.close();
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error obteniendo la información',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const refetchData = () => {
    refListTable.value.refresh()
    loadingSelected.value = false;
    selectedObject.value = null;
  }

  watch(
    [ currentPage,
      perPage, ,
    ], () => {
      refetchData()
    });

  watch([ searchQuery ], debounce(() => { refetchData() }, 500));

  const applyChartFilters = (filters) => {
    if (filters) {
      const status = {};
      status[filters['status'] && Array.isArray(filters['status']) ? filters['status'][0] : '_'] = 1;
      tenantFilter.value = filters['id_tenant'] || null;
      waitingFilter.value = status['waiting'] ? '1' : null;
      attendingFilter.value = status['attending'] ? '1' : null;
      canceledFilter.value = status['canceled'] ? '1' : null;
      attendedFilter.value = status['attended'] ? '1' : null;
      alarmTypeFilter.value = filters['type'] || null;
      statusFilter.value = filters['status'] || null;
      createdFilter.value = filters['created'] || null;
      unitFilter.value = filters['units'] || null;

      if (filters['from'] && filters['to']) {
        startDatetimeFilter.value = filters['from'];
        endDatetimeFilter.value = filters['to'];
        dateFilter.value = ['created'];
      }
    }
  }

  //================ filters counter ================
  const filters = ref({});
  const filtersCount = useFilterCounter(filters);
  const buildFilters = () => {
    filters.value = {
      status: statusFilter.value,
      tenant: tenantFilter.value,
      unit: unitFilter.value,
      alarm: alarmTypeFilter.value,
      camera: cameraFilter.value,
      from: startDatetimeFilter.value,
      to: endDatetimeFilter.value,
    };

    if (statusFilter.value) {
      statusFilter.value.forEach(status => {
        switch (status) {
          case 'waiting':
            waitingFilter.value = '1'
            break
          case 'attending':
            attendingFilter.value = '1'
            break
          case 'canceled':
            canceledFilter.value = '1'
            break
          case 'attended':
            attendedFilter.value = '1'
            break
        }
      });
    }

    refetchData();
  }

  const clearSearchFilters = () => {
    statusFilter.value = [];
    tenantFilter.value = null;
    unitFilter.value = null;
    alarmTypeFilter.value = null;
    cameraFilter.value = null;
    startDatetimeFilter.value = null;
    endDatetimeFilter.value = null;
    waitingFilter.value = null;
    attendingFilter.value = null;
    attendedFilter.value = null;
    canceledFilter.value = null;
    filters.value = {};
    buildFilters();
  }
  //==================================================

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    refetchData,
    attendingFilter,
    waitingFilter,
    attendedFilter,
    canceledFilter,

    statusFilter,
    cameraFilter,

    unitFilter,
    unitOptions,
    tenantOptions,
    tenantFilter,
    alarmTypeOptions,
    alarmTypeFilter,

    startDatetimeFilter,
    endDatetimeFilter,
    dateFilter,
    action,
    disableExport,

    selectedObject,
    loadingSelected,

    applyChartFilters,
    loadingToast,
    // filters counter
    filtersCount,
    buildFilters,
    clearSearchFilters,

    initFilters,
  }
}
